<template>
  <label class="checkbox-container">
    <span class="span-base"><slot></slot></span>
    <input class="base-input" type="checkbox" v-model="rawChecked" :disabled="disabled">
    <span class="span-base checkmark"></span>
  </label>
</template>

<script setup lang="ts">
import { ref, watch, toRef } from 'vue';
// const checked = defineModel<boolean>('checked'); // work with warning :(

const emit = defineEmits<{(event: 'update:checked', checked: boolean): void}>();
const props = withDefaults(defineProps<{checked: boolean, disabled?: boolean}>(), { disabled: false })

const rawChecked = ref(props.checked);
watch(rawChecked, (rawChecked) => { emit('update:checked', rawChecked); });
watch(toRef(() => props.checked), () => { rawChecked.value = props.checked });
</script>
