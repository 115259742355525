import { Store, createStore as baseCreateStore } from 'vuex';
import * as API from './api';
import { RpcClient, AbstractModel_Remote, onAuthChanged, onConnectionChanged, onRequestChanged, extractEntryFieldValue } from '@/rpc_client/apiAbstract';
import { WebSocketRpc } from '@/rpc_client/WebSocketRpc';
import { object_msg } from '@/generated/objectMsg';
import { object_update_msg } from '@/rpc_client/messages';
import Long from 'long';

interface AuthStoreData{
  isConnected: boolean
  isAuthenticated: boolean
  userPhone: string
  userRole: string
  isPolicyAccepted: boolean
  agentContractRequested: boolean
  agentContractConcluded: boolean
}

interface RequestInfo{
  pending: boolean
  error: boolean
}

export interface State {
  models: {
    ActionTypes?: Array<object_msg.ActionType>
    WebsiteActions?: Array<object_msg.WebsiteAction>
    ActionMaterials?: Array<object_msg.ActionMaterial>
    MachineTypes?: Array<object_msg.MachineType>
    MaterialTypes?: Array<object_msg.MaterialType>
    LabelTypes?: Array<object_msg.LabelType>
    Places?: Array<object_msg.Place>
    OrganizationsRu?: Array<object_msg.OrganizationRu>
    OrganizationsRuInfo?: Array<object_msg.OrganizationRuInfo>
    BanksRu?: Array<object_msg.BankRu>
    PlaceActions?: Array<object_msg.PlaceAction>
    PlacePhones?: Array<object_msg.PlacePhone>
    PlaceEmails?: Array<object_msg.PlaceEmail>
    PlaceMachines?: Array<object_msg.PlaceMachine>
    PlaceLabels?: Array<object_msg.PlaceLabel>
    LaserCuttingPrices?: Array<object_msg.LaserCuttingPrice>
    LocalLaserCuttingPrices?: Array<object_msg.LocalLaserCuttingPrice>
    LaserEngravingPrices?: Array<object_msg.LaserEngravingPrice>
    LocalLaserEngravingPrices?: Array<object_msg.LocalLaserEngravingPrice>
    Logs?: Array<object_msg.logEntry>
    CitiesRu?: Array<object_msg.CityRu>
    CityActions?: Array<object_msg.CityAction>
    Orders?: Array<object_msg.Order>
    OrderStatuses?: Array<object_msg.OrderStatus>
    OrderComments?: Array<object_msg.OrderComment>
    OrderCommentFiles?: Array<object_msg.OrderCommentFile>
    UserDeliveryProfiles?: Array<object_msg.UserDeliveryProfile>
    OrderStages?: Array<object_msg.OrderStage>
    OrderStageStatuses?: Array<object_msg.OrderStageStatus>
    OrderStageDeliveryProfiles?: Array<object_msg.OrderStageDeliveryProfile>
    OrderStageComments?: Array<object_msg.OrderStageComment>
    OrderStageCommentFiles?: Array<object_msg.OrderStageCommentFile>
    UploadedFiles?: Array<object_msg.UploadedFile>
    CommercialOfferRequests?: Array<object_msg.CommercialOfferRequest>
    LaserCuttingStages?: Array<object_msg.LaserCuttingStage>
    LaserEngravingStages?: Array<object_msg.LaserEngravingStage>
    WebCrawlerWebsites?: Array<object_msg.WebCrawlerWebsites>
    WebCrawlerPendingPages?: Array<object_msg.WebCrawlerPendingPage>
    WebCrawlerUselessPaths?: Array<object_msg.WebCrawlerUselessPath>
    WebCrawlerPricePaths?: Array<object_msg.WebCrawlerPricePath>
    Departments?: Array<object_msg.Department>
    DepartmentActions?: Array<object_msg.DepartmentAction>
    LaserCuttingMaterialThicknesses?: Array<object_msg.LaserCuttingMaterialThickness>
    ReceiptPositions?: Array<object_msg.ReceiptPosition>
    OrderServices?: Array<object_msg.OrderService>
    SecondaryArticles?: Array<object_msg.SecondaryArticle>
    ArticleAuthors?: Array<object_msg.ArticleAuthor>
  },
  modelsInfo: {
    ActionTypes: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    WebsiteActions: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    ActionMaterials: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    MachineTypes: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    MaterialTypes: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    LabelTypes: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    Places: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrganizationsRu: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrganizationsRuInfo: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    BanksRu: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    PlaceActions: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    PlacePhones: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    PlaceEmails: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    PlaceMachines: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    PlaceLabels: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    LaserCuttingPrices: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    LocalLaserCuttingPrices: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    LaserEngravingPrices: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    LocalLaserEngravingPrices: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    Logs: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    CitiesRu: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    CityActions: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    Orders: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrderStatuses: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrderComments: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrderCommentFiles: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    UserDeliveryProfiles: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrderStages: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrderStageStatuses: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrderStageDeliveryProfiles: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrderStageComments: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrderStageCommentFiles: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    UploadedFiles: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    CommercialOfferRequests: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    LaserCuttingStages: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    LaserEngravingStages: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    WebCrawlerWebsites: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    WebCrawlerPendingPages: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    WebCrawlerUselessPaths: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    WebCrawlerPricePaths: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    Departments: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    DepartmentActions: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    LaserCuttingMaterialThicknesses: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    ReceiptPositions: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    OrderServices: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    SecondaryArticles: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
    ArticleAuthors: { valid: boolean, roleToRoleNameMap: Map<number, string>, indexNames: Array<string> }
  },
  auth: AuthStoreData,
  request: RequestInfo
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class ActionTypesModel_Remote extends API.ActionTypesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {ActionTypes: Array<object_msg.ActionType>} {
    return { ActionTypes: [] };
  }

  getInfoState () {
    return { ActionTypes: { valid: false, roleToRoleNameMap: new Map<number, string>([[2, 'website_main_page_enabled'], [1, 'action'], [6, 'website_main_page_position']]) } };
  }

  getMutations () {
    const mutations = {
      add_ActionType (state: State, payload: object_msg.ActionType) {
        state.models.ActionTypes!.push(payload);
      },
      update_ActionType (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let ActionType of state.models.ActionTypes!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = ActionType[state.modelsInfo.ActionTypes.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            ActionType[state.modelsInfo.ActionTypes.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_ActionType (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.ActionTypes!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.ActionTypes![i][state.modelsInfo.ActionTypes.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.ActionTypes!.splice(i, 1);
          break;
        }
      },
      reset_all_ActionType (state: State, payload: object_msg.ActionType[]) {
        state.models.ActionTypes!.length = 0;
        Array.prototype.push.apply(state.models.ActionTypes, payload);
      },
      clear_all_ActionType (state: State) {
        state.models.ActionTypes!.length = 0;
      },
      set_valid_ActionType (state: State, payload: boolean) {
        state.modelsInfo.ActionTypes.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_ActionType', object_msg.ActionType.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_ActionType', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_ActionType', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_ActionType', object_msg.ActionTypeArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_ActionType');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_ActionType', valid);
  }

  static addMsgObject (dest: object_msg.ActionType): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.ActionType.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.ActionType): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.action }] }) });
  }

  static updateMsgObject (src: object_msg.ActionType, dest: object_msg.ActionType): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.action }];
    if (src.website_main_page_enabled !== dest.website_main_page_enabled) update.data.push({ role: 2, b: dest.website_main_page_enabled });
    if (src.action !== dest.action) update.data.push({ role: 1, s: dest.action });
    if (src.website_main_page_position !== dest.website_main_page_position) update.data.push({ role: 6, i: dest.website_main_page_position });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.ActionType>, dest: Array<object_msg.ActionType>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.action !== dest[i].action) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.ActionType): object_msg.ActionType { 
    return object_msg.ActionType.fromObject(object_msg.ActionType.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class WebsiteActionsModel_Remote extends API.WebsiteActionsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {WebsiteActions: Array<object_msg.WebsiteAction>} {
    return { WebsiteActions: [] };
  }

  getInfoState () {
    return { WebsiteActions: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'website_action_page_enabled'], [4, 'url_name'], [11, 'website_main_action_json_ld'], [10, 'website_main_action_article'], [9, 'website_action_page_head_title_and_meta_description'], [8, 'website_main_page_description'], [14, 'website_main_action_additional_footer_elements'], [13, 'website_main_action_author_id'], [12, 'website_main_action_open_graph'], [3, 'website_contacts_description'], [2, 'website_main_page_enabled'], [1, 'action'], [7, 'website_svg_icon'], [6, 'website_main_page_position']]) } };
  }

  getMutations () {
    const mutations = {
      add_WebsiteAction (state: State, payload: object_msg.WebsiteAction) {
        state.models.WebsiteActions!.push(payload);
      },
      update_WebsiteAction (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let WebsiteAction of state.models.WebsiteActions!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = WebsiteAction[state.modelsInfo.WebsiteActions.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            WebsiteAction[state.modelsInfo.WebsiteActions.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_WebsiteAction (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.WebsiteActions!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.WebsiteActions![i][state.modelsInfo.WebsiteActions.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.WebsiteActions!.splice(i, 1);
          break;
        }
      },
      reset_all_WebsiteAction (state: State, payload: object_msg.WebsiteAction[]) {
        state.models.WebsiteActions!.length = 0;
        Array.prototype.push.apply(state.models.WebsiteActions, payload);
      },
      clear_all_WebsiteAction (state: State) {
        state.models.WebsiteActions!.length = 0;
      },
      set_valid_WebsiteAction (state: State, payload: boolean) {
        state.modelsInfo.WebsiteActions.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_WebsiteAction', object_msg.WebsiteAction.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_WebsiteAction', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_WebsiteAction', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_WebsiteAction', object_msg.WebsiteActionArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_WebsiteAction');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_WebsiteAction', valid);
  }

  static addMsgObject (dest: object_msg.WebsiteAction): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.WebsiteAction.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.WebsiteAction): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.action }] }) });
  }

  static updateMsgObject (src: object_msg.WebsiteAction, dest: object_msg.WebsiteAction): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.action }];
    if (src.website_action_page_enabled !== dest.website_action_page_enabled) update.data.push({ role: 5, b: dest.website_action_page_enabled });
    if (src.url_name !== dest.url_name) update.data.push({ role: 4, s: dest.url_name });
    if (src.website_main_action_json_ld !== dest.website_main_action_json_ld) update.data.push({ role: 11, s: dest.website_main_action_json_ld });
    if (src.website_main_action_article !== dest.website_main_action_article) update.data.push({ role: 10, s: dest.website_main_action_article });
    if (src.website_action_page_head_title_and_meta_description !== dest.website_action_page_head_title_and_meta_description) update.data.push({ role: 9, s: dest.website_action_page_head_title_and_meta_description });
    if (src.website_main_page_description !== dest.website_main_page_description) update.data.push({ role: 8, s: dest.website_main_page_description });
    if (src.website_main_action_additional_footer_elements !== dest.website_main_action_additional_footer_elements) update.data.push({ role: 14, s: dest.website_main_action_additional_footer_elements });
    if (src.website_main_action_author_id.compare(dest.website_main_action_author_id) !== 0) update.data.push({ role: 13, u: dest.website_main_action_author_id });
    if (src.website_main_action_open_graph !== dest.website_main_action_open_graph) update.data.push({ role: 12, s: dest.website_main_action_open_graph });
    if (src.website_contacts_description !== dest.website_contacts_description) update.data.push({ role: 3, s: dest.website_contacts_description });
    if (src.website_main_page_enabled !== dest.website_main_page_enabled) update.data.push({ role: 2, b: dest.website_main_page_enabled });
    if (src.action !== dest.action) update.data.push({ role: 1, s: dest.action });
    if (src.website_svg_icon !== dest.website_svg_icon) update.data.push({ role: 7, s: dest.website_svg_icon });
    if (src.website_main_page_position !== dest.website_main_page_position) update.data.push({ role: 6, i: dest.website_main_page_position });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.WebsiteAction>, dest: Array<object_msg.WebsiteAction>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.action !== dest[i].action) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.WebsiteAction): object_msg.WebsiteAction { 
    return object_msg.WebsiteAction.fromObject(object_msg.WebsiteAction.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class ActionMaterialsModel_Remote extends API.ActionMaterialsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {ActionMaterials: Array<object_msg.ActionMaterial>} {
    return { ActionMaterials: [] };
  }

  getInfoState () {
    return { ActionMaterials: { valid: false, roleToRoleNameMap: new Map<number, string>([[2, 'material'], [1, 'action']]) } };
  }

  getMutations () {
    const mutations = {
      add_ActionMaterial (state: State, payload: object_msg.ActionMaterial) {
        state.models.ActionMaterials!.push(payload);
      },
      update_ActionMaterial (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let ActionMaterial of state.models.ActionMaterials!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = ActionMaterial[state.modelsInfo.ActionMaterials.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            ActionMaterial[state.modelsInfo.ActionMaterials.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_ActionMaterial (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.ActionMaterials!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.ActionMaterials![i][state.modelsInfo.ActionMaterials.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.ActionMaterials!.splice(i, 1);
          break;
        }
      },
      reset_all_ActionMaterial (state: State, payload: object_msg.ActionMaterial[]) {
        state.models.ActionMaterials!.length = 0;
        Array.prototype.push.apply(state.models.ActionMaterials, payload);
      },
      clear_all_ActionMaterial (state: State) {
        state.models.ActionMaterials!.length = 0;
      },
      set_valid_ActionMaterial (state: State, payload: boolean) {
        state.modelsInfo.ActionMaterials.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_ActionMaterial', object_msg.ActionMaterial.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_ActionMaterial', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_ActionMaterial', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_ActionMaterial', object_msg.ActionMaterialArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_ActionMaterial');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_ActionMaterial', valid);
  }

  static addMsgObject (dest: object_msg.ActionMaterial): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.ActionMaterial.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.ActionMaterial): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.action }, { role: 2, s: src.material }] }) });
  }

  static updateMsgObject (src: object_msg.ActionMaterial, dest: object_msg.ActionMaterial): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.action }, { role: 2, s: src.material }];
    if (src.material !== dest.material) update.data.push({ role: 2, s: dest.material });
    if (src.action !== dest.action) update.data.push({ role: 1, s: dest.action });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.ActionMaterial>, dest: Array<object_msg.ActionMaterial>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.action !== dest[i].action) continue;
        if (srcObj.material !== dest[i].material) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.ActionMaterial): object_msg.ActionMaterial { 
    return object_msg.ActionMaterial.fromObject(object_msg.ActionMaterial.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class MachineTypesModel_Remote extends API.MachineTypesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {MachineTypes: Array<object_msg.MachineType>} {
    return { MachineTypes: [] };
  }

  getInfoState () {
    return { MachineTypes: { valid: false, roleToRoleNameMap: new Map<number, string>([[1, 'model']]) } };
  }

  getMutations () {
    const mutations = {
      add_MachineType (state: State, payload: object_msg.MachineType) {
        state.models.MachineTypes!.push(payload);
      },
      update_MachineType (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let MachineType of state.models.MachineTypes!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = MachineType[state.modelsInfo.MachineTypes.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            MachineType[state.modelsInfo.MachineTypes.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_MachineType (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.MachineTypes!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.MachineTypes![i][state.modelsInfo.MachineTypes.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.MachineTypes!.splice(i, 1);
          break;
        }
      },
      reset_all_MachineType (state: State, payload: object_msg.MachineType[]) {
        state.models.MachineTypes!.length = 0;
        Array.prototype.push.apply(state.models.MachineTypes, payload);
      },
      clear_all_MachineType (state: State) {
        state.models.MachineTypes!.length = 0;
      },
      set_valid_MachineType (state: State, payload: boolean) {
        state.modelsInfo.MachineTypes.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_MachineType', object_msg.MachineType.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_MachineType', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_MachineType', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_MachineType', object_msg.MachineTypeArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_MachineType');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_MachineType', valid);
  }

  static addMsgObject (dest: object_msg.MachineType): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.MachineType.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.MachineType): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.model }] }) });
  }

  static updateMsgObject (src: object_msg.MachineType, dest: object_msg.MachineType): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.model }];
    if (src.model !== dest.model) update.data.push({ role: 1, s: dest.model });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.MachineType>, dest: Array<object_msg.MachineType>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.model !== dest[i].model) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.MachineType): object_msg.MachineType { 
    return object_msg.MachineType.fromObject(object_msg.MachineType.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class MaterialTypesModel_Remote extends API.MaterialTypesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {MaterialTypes: Array<object_msg.MaterialType>} {
    return { MaterialTypes: [] };
  }

  getInfoState () {
    return { MaterialTypes: { valid: false, roleToRoleNameMap: new Map<number, string>([[2, 'material_group'], [1, 'material']]) } };
  }

  getMutations () {
    const mutations = {
      add_MaterialType (state: State, payload: object_msg.MaterialType) {
        state.models.MaterialTypes!.push(payload);
      },
      update_MaterialType (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let MaterialType of state.models.MaterialTypes!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = MaterialType[state.modelsInfo.MaterialTypes.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            MaterialType[state.modelsInfo.MaterialTypes.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_MaterialType (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.MaterialTypes!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.MaterialTypes![i][state.modelsInfo.MaterialTypes.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.MaterialTypes!.splice(i, 1);
          break;
        }
      },
      reset_all_MaterialType (state: State, payload: object_msg.MaterialType[]) {
        state.models.MaterialTypes!.length = 0;
        Array.prototype.push.apply(state.models.MaterialTypes, payload);
      },
      clear_all_MaterialType (state: State) {
        state.models.MaterialTypes!.length = 0;
      },
      set_valid_MaterialType (state: State, payload: boolean) {
        state.modelsInfo.MaterialTypes.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_MaterialType', object_msg.MaterialType.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_MaterialType', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_MaterialType', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_MaterialType', object_msg.MaterialTypeArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_MaterialType');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_MaterialType', valid);
  }

  static addMsgObject (dest: object_msg.MaterialType): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.MaterialType.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.MaterialType): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.material }] }) });
  }

  static updateMsgObject (src: object_msg.MaterialType, dest: object_msg.MaterialType): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.material }];
    if (src.material_group !== dest.material_group) update.data.push({ role: 2, s: dest.material_group });
    if (src.material !== dest.material) update.data.push({ role: 1, s: dest.material });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.MaterialType>, dest: Array<object_msg.MaterialType>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.material !== dest[i].material) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.MaterialType): object_msg.MaterialType { 
    return object_msg.MaterialType.fromObject(object_msg.MaterialType.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class LabelTypesModel_Remote extends API.LabelTypesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {LabelTypes: Array<object_msg.LabelType>} {
    return { LabelTypes: [] };
  }

  getInfoState () {
    return { LabelTypes: { valid: false, roleToRoleNameMap: new Map<number, string>([[2, 'value'], [1, 'key']]) } };
  }

  getMutations () {
    const mutations = {
      add_LabelType (state: State, payload: object_msg.LabelType) {
        state.models.LabelTypes!.push(payload);
      },
      update_LabelType (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let LabelType of state.models.LabelTypes!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = LabelType[state.modelsInfo.LabelTypes.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            LabelType[state.modelsInfo.LabelTypes.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_LabelType (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.LabelTypes!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.LabelTypes![i][state.modelsInfo.LabelTypes.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.LabelTypes!.splice(i, 1);
          break;
        }
      },
      reset_all_LabelType (state: State, payload: object_msg.LabelType[]) {
        state.models.LabelTypes!.length = 0;
        Array.prototype.push.apply(state.models.LabelTypes, payload);
      },
      clear_all_LabelType (state: State) {
        state.models.LabelTypes!.length = 0;
      },
      set_valid_LabelType (state: State, payload: boolean) {
        state.modelsInfo.LabelTypes.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_LabelType', object_msg.LabelType.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_LabelType', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_LabelType', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_LabelType', object_msg.LabelTypeArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_LabelType');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_LabelType', valid);
  }

  static addMsgObject (dest: object_msg.LabelType): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.LabelType.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.LabelType): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.key }] }) });
  }

  static updateMsgObject (src: object_msg.LabelType, dest: object_msg.LabelType): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.key }];
    if (src.value !== dest.value) update.data.push({ role: 2, s: dest.value });
    if (src.key !== dest.key) update.data.push({ role: 1, s: dest.key });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.LabelType>, dest: Array<object_msg.LabelType>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.key !== dest[i].key) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.LabelType): object_msg.LabelType { 
    return object_msg.LabelType.fromObject(object_msg.LabelType.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class PlacesModel_Remote extends API.PlacesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {Places: Array<object_msg.Place>} {
    return { Places: [] };
  }

  getInfoState () {
    return { Places: { valid: false, roleToRoleNameMap: new Map<number, string>([[19, 'yandex_rating'], [18, 'youtube_url'], [17, 'ok_url'], [16, 'vk_url'], [22, 'creator_user_id'], [21, 'telegram_id'], [20, 'yandex_rating_count'], [3, 'town'], [2, 'name'], [1, 'id'], [7, 'archive'], [6, 'longitude'], [5, 'latitude'], [4, 'address'], [11, 'address_quality'], [10, 'description'], [9, 'rating'], [8, 'creation_time'], [15, 'map_url'], [14, 'verified'], [13, 'url'], [12, 'organization_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_Place (state: State, payload: object_msg.Place) {
        state.models.Places!.push(payload);
      },
      update_Place (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let Place of state.models.Places!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = Place[state.modelsInfo.Places.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            Place[state.modelsInfo.Places.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_Place (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.Places!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.Places![i][state.modelsInfo.Places.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.Places!.splice(i, 1);
          break;
        }
      },
      reset_all_Place (state: State, payload: object_msg.Place[]) {
        state.models.Places!.length = 0;
        Array.prototype.push.apply(state.models.Places, payload);
      },
      clear_all_Place (state: State) {
        state.models.Places!.length = 0;
      },
      set_valid_Place (state: State, payload: boolean) {
        state.modelsInfo.Places.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_Place', object_msg.Place.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_Place', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_Place', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_Place', object_msg.PlaceArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_Place');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_Place', valid);
  }

  static addMsgObject (dest: object_msg.Place): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.Place.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.Place): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.Place, dest: object_msg.Place): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.yandex_rating !== dest.yandex_rating) update.data.push({ role: 19, f: dest.yandex_rating });
    if (src.youtube_url !== dest.youtube_url) update.data.push({ role: 18, s: dest.youtube_url });
    if (src.ok_url !== dest.ok_url) update.data.push({ role: 17, s: dest.ok_url });
    if (src.vk_url !== dest.vk_url) update.data.push({ role: 16, s: dest.vk_url });
    if (src.creator_user_id.compare(dest.creator_user_id) !== 0) update.data.push({ role: 22, u: dest.creator_user_id });
    if (src.telegram_id !== dest.telegram_id) update.data.push({ role: 21, s: dest.telegram_id });
    if (src.yandex_rating_count !== dest.yandex_rating_count) update.data.push({ role: 20, i: dest.yandex_rating_count });
    if (src.town !== dest.town) update.data.push({ role: 3, s: dest.town });
    if (src.name !== dest.name) update.data.push({ role: 2, s: dest.name });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    if (src.archive !== dest.archive) update.data.push({ role: 7, b: dest.archive });
    if (src.longitude !== dest.longitude) update.data.push({ role: 6, f: dest.longitude });
    if (src.latitude !== dest.latitude) update.data.push({ role: 5, f: dest.latitude });
    if (src.address !== dest.address) update.data.push({ role: 4, s: dest.address });
    if (src.address_quality !== dest.address_quality) update.data.push({ role: 11, i: dest.address_quality });
    if (src.description !== dest.description) update.data.push({ role: 10, s: dest.description });
    if (src.rating !== dest.rating) update.data.push({ role: 9, i: dest.rating });
    if (src.creation_time.compare(dest.creation_time) !== 0) update.data.push({ role: 8, dt: dest.creation_time });
    if (src.map_url !== dest.map_url) update.data.push({ role: 15, s: dest.map_url });
    if (src.verified !== dest.verified) update.data.push({ role: 14, b: dest.verified });
    if (src.url !== dest.url) update.data.push({ role: 13, s: dest.url });
    if (src.organization_id.compare(dest.organization_id) !== 0) update.data.push({ role: 12, u: dest.organization_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.Place>, dest: Array<object_msg.Place>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.Place): object_msg.Place { 
    return object_msg.Place.fromObject(object_msg.Place.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrganizationsRuModel_Remote extends API.OrganizationsRuModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrganizationsRu: Array<object_msg.OrganizationRu>} {
    return { OrganizationsRu: [] };
  }

  getInfoState () {
    return { OrganizationsRu: { valid: false, roleToRoleNameMap: new Map<number, string>([[19, 'verified'], [18, 'phone'], [17, 'legal_address_quality'], [16, 'archive'], [21, 'creation_time'], [20, 'creator_user_id'], [3, 'inn'], [2, 'name'], [1, 'id'], [7, 'with_vat'], [6, 'legal_address'], [5, 'ogrn'], [4, 'kpp'], [11, 'bank_id'], [10, 'representative_position'], [9, 'representative_name'], [8, 'vat_checked'], [15, 'mailing_address_quality'], [14, 'mailing_address'], [13, 'organization_type'], [12, 'payment_account']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrganizationRu (state: State, payload: object_msg.OrganizationRu) {
        state.models.OrganizationsRu!.push(payload);
      },
      update_OrganizationRu (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrganizationRu of state.models.OrganizationsRu!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrganizationRu[state.modelsInfo.OrganizationsRu.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrganizationRu[state.modelsInfo.OrganizationsRu.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrganizationRu (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrganizationsRu!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrganizationsRu![i][state.modelsInfo.OrganizationsRu.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrganizationsRu!.splice(i, 1);
          break;
        }
      },
      reset_all_OrganizationRu (state: State, payload: object_msg.OrganizationRu[]) {
        state.models.OrganizationsRu!.length = 0;
        Array.prototype.push.apply(state.models.OrganizationsRu, payload);
      },
      clear_all_OrganizationRu (state: State) {
        state.models.OrganizationsRu!.length = 0;
      },
      set_valid_OrganizationRu (state: State, payload: boolean) {
        state.modelsInfo.OrganizationsRu.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrganizationRu', object_msg.OrganizationRu.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrganizationRu', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrganizationRu', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrganizationRu', object_msg.OrganizationRuArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrganizationRu');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrganizationRu', valid);
  }

  static addMsgObject (dest: object_msg.OrganizationRu): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrganizationRu.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrganizationRu): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.OrganizationRu, dest: object_msg.OrganizationRu): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.verified !== dest.verified) update.data.push({ role: 19, b: dest.verified });
    if (src.phone !== dest.phone) update.data.push({ role: 18, s: dest.phone });
    if (src.legal_address_quality !== dest.legal_address_quality) update.data.push({ role: 17, i: dest.legal_address_quality });
    if (src.archive !== dest.archive) update.data.push({ role: 16, b: dest.archive });
    if (src.creation_time.compare(dest.creation_time) !== 0) update.data.push({ role: 21, dt: dest.creation_time });
    if (src.creator_user_id.compare(dest.creator_user_id) !== 0) update.data.push({ role: 20, u: dest.creator_user_id });
    if (src.inn !== dest.inn) update.data.push({ role: 3, s: dest.inn });
    if (src.name !== dest.name) update.data.push({ role: 2, s: dest.name });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    if (src.with_vat !== dest.with_vat) update.data.push({ role: 7, b: dest.with_vat });
    if (src.legal_address !== dest.legal_address) update.data.push({ role: 6, s: dest.legal_address });
    if (src.ogrn !== dest.ogrn) update.data.push({ role: 5, s: dest.ogrn });
    if (src.kpp !== dest.kpp) update.data.push({ role: 4, s: dest.kpp });
    if (src.bank_id.compare(dest.bank_id) !== 0) update.data.push({ role: 11, u: dest.bank_id });
    if (src.representative_position !== dest.representative_position) update.data.push({ role: 10, s: dest.representative_position });
    if (src.representative_name !== dest.representative_name) update.data.push({ role: 9, s: dest.representative_name });
    if (src.vat_checked !== dest.vat_checked) update.data.push({ role: 8, b: dest.vat_checked });
    if (src.mailing_address_quality !== dest.mailing_address_quality) update.data.push({ role: 15, i: dest.mailing_address_quality });
    if (src.mailing_address !== dest.mailing_address) update.data.push({ role: 14, s: dest.mailing_address });
    if (src.organization_type !== dest.organization_type) update.data.push({ role: 13, s: dest.organization_type });
    if (src.payment_account !== dest.payment_account) update.data.push({ role: 12, s: dest.payment_account });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrganizationRu>, dest: Array<object_msg.OrganizationRu>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrganizationRu): object_msg.OrganizationRu { 
    return object_msg.OrganizationRu.fromObject(object_msg.OrganizationRu.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrganizationsRuInfoModel_Remote extends API.OrganizationsRuInfoModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrganizationsRuInfo: Array<object_msg.OrganizationRuInfo>} {
    return { OrganizationsRuInfo: [] };
  }

  getInfoState () {
    return { OrganizationsRuInfo: { valid: false, roleToRoleNameMap: new Map<number, string>([[4, 'kpp'], [3, 'inn'], [2, 'name'], [19, 'verified'], [1, 'id'], [16, 'archive'], [6, 'legal_address']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrganizationRuInfo (state: State, payload: object_msg.OrganizationRuInfo) {
        state.models.OrganizationsRuInfo!.push(payload);
      },
      update_OrganizationRuInfo (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrganizationRuInfo of state.models.OrganizationsRuInfo!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrganizationRuInfo[state.modelsInfo.OrganizationsRuInfo.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrganizationRuInfo[state.modelsInfo.OrganizationsRuInfo.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrganizationRuInfo (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrganizationsRuInfo!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrganizationsRuInfo![i][state.modelsInfo.OrganizationsRuInfo.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrganizationsRuInfo!.splice(i, 1);
          break;
        }
      },
      reset_all_OrganizationRuInfo (state: State, payload: object_msg.OrganizationRuInfo[]) {
        state.models.OrganizationsRuInfo!.length = 0;
        Array.prototype.push.apply(state.models.OrganizationsRuInfo, payload);
      },
      clear_all_OrganizationRuInfo (state: State) {
        state.models.OrganizationsRuInfo!.length = 0;
      },
      set_valid_OrganizationRuInfo (state: State, payload: boolean) {
        state.modelsInfo.OrganizationsRuInfo.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrganizationRuInfo', object_msg.OrganizationRuInfo.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrganizationRuInfo', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrganizationRuInfo', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrganizationRuInfo', object_msg.OrganizationRuInfoArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrganizationRuInfo');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrganizationRuInfo', valid);
  }

  static addMsgObject (dest: object_msg.OrganizationRuInfo): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrganizationRuInfo.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrganizationRuInfo): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.OrganizationRuInfo, dest: object_msg.OrganizationRuInfo): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.kpp !== dest.kpp) update.data.push({ role: 4, s: dest.kpp });
    if (src.inn !== dest.inn) update.data.push({ role: 3, s: dest.inn });
    if (src.name !== dest.name) update.data.push({ role: 2, s: dest.name });
    if (src.verified !== dest.verified) update.data.push({ role: 19, b: dest.verified });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    if (src.archive !== dest.archive) update.data.push({ role: 16, b: dest.archive });
    if (src.legal_address !== dest.legal_address) update.data.push({ role: 6, s: dest.legal_address });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrganizationRuInfo>, dest: Array<object_msg.OrganizationRuInfo>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrganizationRuInfo): object_msg.OrganizationRuInfo { 
    return object_msg.OrganizationRuInfo.fromObject(object_msg.OrganizationRuInfo.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class BanksRuModel_Remote extends API.BanksRuModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {BanksRu: Array<object_msg.BankRu>} {
    return { BanksRu: [] };
  }

  getInfoState () {
    return { BanksRu: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'inn'], [4, 'swift'], [8, 'legal_address'], [3, 'bic'], [2, 'name'], [1, 'id'], [7, 'correspondent_account'], [6, 'kpp']]) } };
  }

  getMutations () {
    const mutations = {
      add_BankRu (state: State, payload: object_msg.BankRu) {
        state.models.BanksRu!.push(payload);
      },
      update_BankRu (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let BankRu of state.models.BanksRu!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = BankRu[state.modelsInfo.BanksRu.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            BankRu[state.modelsInfo.BanksRu.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_BankRu (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.BanksRu!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.BanksRu![i][state.modelsInfo.BanksRu.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.BanksRu!.splice(i, 1);
          break;
        }
      },
      reset_all_BankRu (state: State, payload: object_msg.BankRu[]) {
        state.models.BanksRu!.length = 0;
        Array.prototype.push.apply(state.models.BanksRu, payload);
      },
      clear_all_BankRu (state: State) {
        state.models.BanksRu!.length = 0;
      },
      set_valid_BankRu (state: State, payload: boolean) {
        state.modelsInfo.BanksRu.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_BankRu', object_msg.BankRu.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_BankRu', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_BankRu', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_BankRu', object_msg.BankRuArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_BankRu');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_BankRu', valid);
  }

  static addMsgObject (dest: object_msg.BankRu): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.BankRu.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.BankRu): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.BankRu, dest: object_msg.BankRu): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.inn !== dest.inn) update.data.push({ role: 5, s: dest.inn });
    if (src.swift !== dest.swift) update.data.push({ role: 4, s: dest.swift });
    if (src.legal_address !== dest.legal_address) update.data.push({ role: 8, s: dest.legal_address });
    if (src.bic !== dest.bic) update.data.push({ role: 3, s: dest.bic });
    if (src.name !== dest.name) update.data.push({ role: 2, s: dest.name });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    if (src.correspondent_account !== dest.correspondent_account) update.data.push({ role: 7, s: dest.correspondent_account });
    if (src.kpp !== dest.kpp) update.data.push({ role: 6, s: dest.kpp });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.BankRu>, dest: Array<object_msg.BankRu>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.BankRu): object_msg.BankRu { 
    return object_msg.BankRu.fromObject(object_msg.BankRu.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class PlaceActionsModel_Remote extends API.PlaceActionsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {PlaceActions: Array<object_msg.PlaceAction>} {
    return { PlaceActions: [] };
  }

  getInfoState () {
    return { PlaceActions: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'city_id'], [3, 'unified'], [2, 'action'], [1, 'place_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_PlaceAction (state: State, payload: object_msg.PlaceAction) {
        state.models.PlaceActions!.push(payload);
      },
      update_PlaceAction (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let PlaceAction of state.models.PlaceActions!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = PlaceAction[state.modelsInfo.PlaceActions.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            PlaceAction[state.modelsInfo.PlaceActions.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_PlaceAction (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.PlaceActions!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.PlaceActions![i][state.modelsInfo.PlaceActions.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.PlaceActions!.splice(i, 1);
          break;
        }
      },
      reset_all_PlaceAction (state: State, payload: object_msg.PlaceAction[]) {
        state.models.PlaceActions!.length = 0;
        Array.prototype.push.apply(state.models.PlaceActions, payload);
      },
      clear_all_PlaceAction (state: State) {
        state.models.PlaceActions!.length = 0;
      },
      set_valid_PlaceAction (state: State, payload: boolean) {
        state.modelsInfo.PlaceActions.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_PlaceAction', object_msg.PlaceAction.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_PlaceAction', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_PlaceAction', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_PlaceAction', object_msg.PlaceActionArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_PlaceAction');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_PlaceAction', valid);
  }

  static addMsgObject (dest: object_msg.PlaceAction): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.PlaceAction.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.PlaceAction): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.place_id }, { role: 2, s: src.action }] }) });
  }

  static updateMsgObject (src: object_msg.PlaceAction, dest: object_msg.PlaceAction): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.place_id }, { role: 2, s: src.action }];
    if (src.city_id.compare(dest.city_id) !== 0) update.data.push({ role: 5, u: dest.city_id });
    if (src.unified !== dest.unified) update.data.push({ role: 3, b: dest.unified });
    if (src.action !== dest.action) update.data.push({ role: 2, s: dest.action });
    if (src.place_id.compare(dest.place_id) !== 0) update.data.push({ role: 1, u: dest.place_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.PlaceAction>, dest: Array<object_msg.PlaceAction>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.place_id.compare(dest[i].place_id) !== 0) continue;
        if (srcObj.action !== dest[i].action) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.PlaceAction): object_msg.PlaceAction { 
    return object_msg.PlaceAction.fromObject(object_msg.PlaceAction.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class PlacePhonesModel_Remote extends API.PlacePhonesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {PlacePhones: Array<object_msg.PlacePhone>} {
    return { PlacePhones: [] };
  }

  getInfoState () {
    return { PlacePhones: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'main'], [4, 'telegram'], [3, 'whatsapp'], [2, 'phone'], [1, 'place_id'], [7, 'description'], [6, 'viber']]) } };
  }

  getMutations () {
    const mutations = {
      add_PlacePhone (state: State, payload: object_msg.PlacePhone) {
        state.models.PlacePhones!.push(payload);
      },
      update_PlacePhone (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let PlacePhone of state.models.PlacePhones!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = PlacePhone[state.modelsInfo.PlacePhones.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            PlacePhone[state.modelsInfo.PlacePhones.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_PlacePhone (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.PlacePhones!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.PlacePhones![i][state.modelsInfo.PlacePhones.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.PlacePhones!.splice(i, 1);
          break;
        }
      },
      reset_all_PlacePhone (state: State, payload: object_msg.PlacePhone[]) {
        state.models.PlacePhones!.length = 0;
        Array.prototype.push.apply(state.models.PlacePhones, payload);
      },
      clear_all_PlacePhone (state: State) {
        state.models.PlacePhones!.length = 0;
      },
      set_valid_PlacePhone (state: State, payload: boolean) {
        state.modelsInfo.PlacePhones.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_PlacePhone', object_msg.PlacePhone.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_PlacePhone', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_PlacePhone', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_PlacePhone', object_msg.PlacePhoneArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_PlacePhone');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_PlacePhone', valid);
  }

  static addMsgObject (dest: object_msg.PlacePhone): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.PlacePhone.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.PlacePhone): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.place_id }, { role: 2, s: src.phone }] }) });
  }

  static updateMsgObject (src: object_msg.PlacePhone, dest: object_msg.PlacePhone): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.place_id }, { role: 2, s: src.phone }];
    if (src.main !== dest.main) update.data.push({ role: 5, b: dest.main });
    if (src.telegram !== dest.telegram) update.data.push({ role: 4, b: dest.telegram });
    if (src.whatsapp !== dest.whatsapp) update.data.push({ role: 3, b: dest.whatsapp });
    if (src.phone !== dest.phone) update.data.push({ role: 2, s: dest.phone });
    if (src.place_id.compare(dest.place_id) !== 0) update.data.push({ role: 1, u: dest.place_id });
    if (src.description !== dest.description) update.data.push({ role: 7, s: dest.description });
    if (src.viber !== dest.viber) update.data.push({ role: 6, b: dest.viber });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.PlacePhone>, dest: Array<object_msg.PlacePhone>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.place_id.compare(dest[i].place_id) !== 0) continue;
        if (srcObj.phone !== dest[i].phone) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.PlacePhone): object_msg.PlacePhone { 
    return object_msg.PlacePhone.fromObject(object_msg.PlacePhone.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class PlaceEmailsModel_Remote extends API.PlaceEmailsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {PlaceEmails: Array<object_msg.PlaceEmail>} {
    return { PlaceEmails: [] };
  }

  getInfoState () {
    return { PlaceEmails: { valid: false, roleToRoleNameMap: new Map<number, string>([[4, 'description'], [3, 'main'], [2, 'email'], [1, 'place_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_PlaceEmail (state: State, payload: object_msg.PlaceEmail) {
        state.models.PlaceEmails!.push(payload);
      },
      update_PlaceEmail (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let PlaceEmail of state.models.PlaceEmails!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = PlaceEmail[state.modelsInfo.PlaceEmails.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            PlaceEmail[state.modelsInfo.PlaceEmails.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_PlaceEmail (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.PlaceEmails!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.PlaceEmails![i][state.modelsInfo.PlaceEmails.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.PlaceEmails!.splice(i, 1);
          break;
        }
      },
      reset_all_PlaceEmail (state: State, payload: object_msg.PlaceEmail[]) {
        state.models.PlaceEmails!.length = 0;
        Array.prototype.push.apply(state.models.PlaceEmails, payload);
      },
      clear_all_PlaceEmail (state: State) {
        state.models.PlaceEmails!.length = 0;
      },
      set_valid_PlaceEmail (state: State, payload: boolean) {
        state.modelsInfo.PlaceEmails.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_PlaceEmail', object_msg.PlaceEmail.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_PlaceEmail', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_PlaceEmail', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_PlaceEmail', object_msg.PlaceEmailArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_PlaceEmail');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_PlaceEmail', valid);
  }

  static addMsgObject (dest: object_msg.PlaceEmail): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.PlaceEmail.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.PlaceEmail): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.place_id }, { role: 2, s: src.email }] }) });
  }

  static updateMsgObject (src: object_msg.PlaceEmail, dest: object_msg.PlaceEmail): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.place_id }, { role: 2, s: src.email }];
    if (src.description !== dest.description) update.data.push({ role: 4, s: dest.description });
    if (src.main !== dest.main) update.data.push({ role: 3, b: dest.main });
    if (src.email !== dest.email) update.data.push({ role: 2, s: dest.email });
    if (src.place_id.compare(dest.place_id) !== 0) update.data.push({ role: 1, u: dest.place_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.PlaceEmail>, dest: Array<object_msg.PlaceEmail>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.place_id.compare(dest[i].place_id) !== 0) continue;
        if (srcObj.email !== dest[i].email) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.PlaceEmail): object_msg.PlaceEmail { 
    return object_msg.PlaceEmail.fromObject(object_msg.PlaceEmail.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class PlaceMachinesModel_Remote extends API.PlaceMachinesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {PlaceMachines: Array<object_msg.PlaceMachine>} {
    return { PlaceMachines: [] };
  }

  getInfoState () {
    return { PlaceMachines: { valid: false, roleToRoleNameMap: new Map<number, string>([[3, 'unified'], [2, 'model'], [1, 'place_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_PlaceMachine (state: State, payload: object_msg.PlaceMachine) {
        state.models.PlaceMachines!.push(payload);
      },
      update_PlaceMachine (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let PlaceMachine of state.models.PlaceMachines!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = PlaceMachine[state.modelsInfo.PlaceMachines.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            PlaceMachine[state.modelsInfo.PlaceMachines.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_PlaceMachine (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.PlaceMachines!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.PlaceMachines![i][state.modelsInfo.PlaceMachines.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.PlaceMachines!.splice(i, 1);
          break;
        }
      },
      reset_all_PlaceMachine (state: State, payload: object_msg.PlaceMachine[]) {
        state.models.PlaceMachines!.length = 0;
        Array.prototype.push.apply(state.models.PlaceMachines, payload);
      },
      clear_all_PlaceMachine (state: State) {
        state.models.PlaceMachines!.length = 0;
      },
      set_valid_PlaceMachine (state: State, payload: boolean) {
        state.modelsInfo.PlaceMachines.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_PlaceMachine', object_msg.PlaceMachine.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_PlaceMachine', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_PlaceMachine', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_PlaceMachine', object_msg.PlaceMachineArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_PlaceMachine');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_PlaceMachine', valid);
  }

  static addMsgObject (dest: object_msg.PlaceMachine): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.PlaceMachine.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.PlaceMachine): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.place_id }, { role: 2, s: src.model }] }) });
  }

  static updateMsgObject (src: object_msg.PlaceMachine, dest: object_msg.PlaceMachine): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.place_id }, { role: 2, s: src.model }];
    if (src.unified !== dest.unified) update.data.push({ role: 3, b: dest.unified });
    if (src.model !== dest.model) update.data.push({ role: 2, s: dest.model });
    if (src.place_id.compare(dest.place_id) !== 0) update.data.push({ role: 1, u: dest.place_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.PlaceMachine>, dest: Array<object_msg.PlaceMachine>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.place_id.compare(dest[i].place_id) !== 0) continue;
        if (srcObj.model !== dest[i].model) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.PlaceMachine): object_msg.PlaceMachine { 
    return object_msg.PlaceMachine.fromObject(object_msg.PlaceMachine.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class PlaceLabelsModel_Remote extends API.PlaceLabelsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {PlaceLabels: Array<object_msg.PlaceLabel>} {
    return { PlaceLabels: [] };
  }

  getInfoState () {
    return { PlaceLabels: { valid: false, roleToRoleNameMap: new Map<number, string>([[4, 'unified'], [3, 'value'], [2, 'key'], [1, 'place_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_PlaceLabel (state: State, payload: object_msg.PlaceLabel) {
        state.models.PlaceLabels!.push(payload);
      },
      update_PlaceLabel (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let PlaceLabel of state.models.PlaceLabels!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = PlaceLabel[state.modelsInfo.PlaceLabels.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            PlaceLabel[state.modelsInfo.PlaceLabels.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_PlaceLabel (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.PlaceLabels!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.PlaceLabels![i][state.modelsInfo.PlaceLabels.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.PlaceLabels!.splice(i, 1);
          break;
        }
      },
      reset_all_PlaceLabel (state: State, payload: object_msg.PlaceLabel[]) {
        state.models.PlaceLabels!.length = 0;
        Array.prototype.push.apply(state.models.PlaceLabels, payload);
      },
      clear_all_PlaceLabel (state: State) {
        state.models.PlaceLabels!.length = 0;
      },
      set_valid_PlaceLabel (state: State, payload: boolean) {
        state.modelsInfo.PlaceLabels.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_PlaceLabel', object_msg.PlaceLabel.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_PlaceLabel', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_PlaceLabel', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_PlaceLabel', object_msg.PlaceLabelArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_PlaceLabel');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_PlaceLabel', valid);
  }

  static addMsgObject (dest: object_msg.PlaceLabel): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.PlaceLabel.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.PlaceLabel): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.place_id }, { role: 2, s: src.key }, { role: 3, s: src.value }] }) });
  }

  static updateMsgObject (src: object_msg.PlaceLabel, dest: object_msg.PlaceLabel): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.place_id }, { role: 2, s: src.key }, { role: 3, s: src.value }];
    if (src.unified !== dest.unified) update.data.push({ role: 4, b: dest.unified });
    if (src.value !== dest.value) update.data.push({ role: 3, s: dest.value });
    if (src.key !== dest.key) update.data.push({ role: 2, s: dest.key });
    if (src.place_id.compare(dest.place_id) !== 0) update.data.push({ role: 1, u: dest.place_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.PlaceLabel>, dest: Array<object_msg.PlaceLabel>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.place_id.compare(dest[i].place_id) !== 0) continue;
        if (srcObj.key !== dest[i].key) continue;
        if (srcObj.value !== dest[i].value) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.PlaceLabel): object_msg.PlaceLabel { 
    return object_msg.PlaceLabel.fromObject(object_msg.PlaceLabel.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class LaserCuttingPricesModel_Remote extends API.LaserCuttingPricesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {LaserCuttingPrices: Array<object_msg.LaserCuttingPrice>} {
    return { LaserCuttingPrices: [] };
  }

  getInfoState () {
    return { LaserCuttingPrices: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'max_m'], [4, 'min_m'], [9, 'approved_price'], [8, 'material_unified'], [3, 'thickness_01mm'], [2, 'material'], [1, 'place_id'], [7, 'currency'], [6, 'price']]) } };
  }

  getMutations () {
    const mutations = {
      add_LaserCuttingPrice (state: State, payload: object_msg.LaserCuttingPrice) {
        state.models.LaserCuttingPrices!.push(payload);
      },
      update_LaserCuttingPrice (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let LaserCuttingPrice of state.models.LaserCuttingPrices!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = LaserCuttingPrice[state.modelsInfo.LaserCuttingPrices.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            LaserCuttingPrice[state.modelsInfo.LaserCuttingPrices.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_LaserCuttingPrice (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.LaserCuttingPrices!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.LaserCuttingPrices![i][state.modelsInfo.LaserCuttingPrices.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.LaserCuttingPrices!.splice(i, 1);
          break;
        }
      },
      reset_all_LaserCuttingPrice (state: State, payload: object_msg.LaserCuttingPrice[]) {
        state.models.LaserCuttingPrices!.length = 0;
        Array.prototype.push.apply(state.models.LaserCuttingPrices, payload);
      },
      clear_all_LaserCuttingPrice (state: State) {
        state.models.LaserCuttingPrices!.length = 0;
      },
      set_valid_LaserCuttingPrice (state: State, payload: boolean) {
        state.modelsInfo.LaserCuttingPrices.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_LaserCuttingPrice', object_msg.LaserCuttingPrice.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_LaserCuttingPrice', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_LaserCuttingPrice', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_LaserCuttingPrice', object_msg.LaserCuttingPriceArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_LaserCuttingPrice');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_LaserCuttingPrice', valid);
  }

  static addMsgObject (dest: object_msg.LaserCuttingPrice): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.LaserCuttingPrice.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.LaserCuttingPrice): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.place_id }, { role: 2, s: src.material }, { role: 3, i: src.thickness_01mm }, { role: 4, i: src.min_m }, { role: 5, i: src.max_m }] }) });
  }

  static updateMsgObject (src: object_msg.LaserCuttingPrice, dest: object_msg.LaserCuttingPrice): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.place_id }, { role: 2, s: src.material }, { role: 3, i: src.thickness_01mm }, { role: 4, i: src.min_m }, { role: 5, i: src.max_m }];
    if (src.max_m !== dest.max_m) update.data.push({ role: 5, i: dest.max_m });
    if (src.min_m !== dest.min_m) update.data.push({ role: 4, i: dest.min_m });
    if (src.approved_price !== dest.approved_price) update.data.push({ role: 9, b: dest.approved_price });
    if (src.material_unified !== dest.material_unified) update.data.push({ role: 8, b: dest.material_unified });
    if (src.thickness_01mm !== dest.thickness_01mm) update.data.push({ role: 3, i: dest.thickness_01mm });
    if (src.material !== dest.material) update.data.push({ role: 2, s: dest.material });
    if (src.place_id.compare(dest.place_id) !== 0) update.data.push({ role: 1, u: dest.place_id });
    if (src.currency !== dest.currency) update.data.push({ role: 7, s: dest.currency });
    if (src.price !== dest.price) update.data.push({ role: 6, f: dest.price });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.LaserCuttingPrice>, dest: Array<object_msg.LaserCuttingPrice>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.place_id.compare(dest[i].place_id) !== 0) continue;
        if (srcObj.material !== dest[i].material) continue;
        if (srcObj.thickness_01mm !== dest[i].thickness_01mm) continue;
        if (srcObj.min_m !== dest[i].min_m) continue;
        if (srcObj.max_m !== dest[i].max_m) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.LaserCuttingPrice): object_msg.LaserCuttingPrice { 
    return object_msg.LaserCuttingPrice.fromObject(object_msg.LaserCuttingPrice.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class LocalLaserCuttingPricesModel_Remote extends API.LocalLaserCuttingPricesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {LocalLaserCuttingPrices: Array<object_msg.LocalLaserCuttingPrice>} {
    return { LocalLaserCuttingPrices: [] };
  }

  getInfoState () {
    return { LocalLaserCuttingPrices: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'max_m'], [4, 'min_m'], [8, 'reference_place_id'], [3, 'thickness_01mm'], [2, 'material'], [1, 'department_id'], [7, 'currency'], [6, 'price']]) } };
  }

  getMutations () {
    const mutations = {
      add_LocalLaserCuttingPrice (state: State, payload: object_msg.LocalLaserCuttingPrice) {
        state.models.LocalLaserCuttingPrices!.push(payload);
      },
      update_LocalLaserCuttingPrice (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let LocalLaserCuttingPrice of state.models.LocalLaserCuttingPrices!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = LocalLaserCuttingPrice[state.modelsInfo.LocalLaserCuttingPrices.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            LocalLaserCuttingPrice[state.modelsInfo.LocalLaserCuttingPrices.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_LocalLaserCuttingPrice (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.LocalLaserCuttingPrices!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.LocalLaserCuttingPrices![i][state.modelsInfo.LocalLaserCuttingPrices.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.LocalLaserCuttingPrices!.splice(i, 1);
          break;
        }
      },
      reset_all_LocalLaserCuttingPrice (state: State, payload: object_msg.LocalLaserCuttingPrice[]) {
        state.models.LocalLaserCuttingPrices!.length = 0;
        Array.prototype.push.apply(state.models.LocalLaserCuttingPrices, payload);
      },
      clear_all_LocalLaserCuttingPrice (state: State) {
        state.models.LocalLaserCuttingPrices!.length = 0;
      },
      set_valid_LocalLaserCuttingPrice (state: State, payload: boolean) {
        state.modelsInfo.LocalLaserCuttingPrices.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_LocalLaserCuttingPrice', object_msg.LocalLaserCuttingPrice.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_LocalLaserCuttingPrice', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_LocalLaserCuttingPrice', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_LocalLaserCuttingPrice', object_msg.LocalLaserCuttingPriceArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_LocalLaserCuttingPrice');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_LocalLaserCuttingPrice', valid);
  }

  static addMsgObject (dest: object_msg.LocalLaserCuttingPrice): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.LocalLaserCuttingPrice.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.LocalLaserCuttingPrice): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.department_id }, { role: 2, s: src.material }, { role: 3, i: src.thickness_01mm }, { role: 4, i: src.min_m }, { role: 5, i: src.max_m }] }) });
  }

  static updateMsgObject (src: object_msg.LocalLaserCuttingPrice, dest: object_msg.LocalLaserCuttingPrice): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.department_id }, { role: 2, s: src.material }, { role: 3, i: src.thickness_01mm }, { role: 4, i: src.min_m }, { role: 5, i: src.max_m }];
    if (src.max_m !== dest.max_m) update.data.push({ role: 5, i: dest.max_m });
    if (src.min_m !== dest.min_m) update.data.push({ role: 4, i: dest.min_m });
    if (src.reference_place_id.compare(dest.reference_place_id) !== 0) update.data.push({ role: 8, u: dest.reference_place_id });
    if (src.thickness_01mm !== dest.thickness_01mm) update.data.push({ role: 3, i: dest.thickness_01mm });
    if (src.material !== dest.material) update.data.push({ role: 2, s: dest.material });
    if (src.department_id.compare(dest.department_id) !== 0) update.data.push({ role: 1, u: dest.department_id });
    if (src.currency !== dest.currency) update.data.push({ role: 7, s: dest.currency });
    if (src.price !== dest.price) update.data.push({ role: 6, f: dest.price });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.LocalLaserCuttingPrice>, dest: Array<object_msg.LocalLaserCuttingPrice>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.department_id.compare(dest[i].department_id) !== 0) continue;
        if (srcObj.material !== dest[i].material) continue;
        if (srcObj.thickness_01mm !== dest[i].thickness_01mm) continue;
        if (srcObj.min_m !== dest[i].min_m) continue;
        if (srcObj.max_m !== dest[i].max_m) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.LocalLaserCuttingPrice): object_msg.LocalLaserCuttingPrice { 
    return object_msg.LocalLaserCuttingPrice.fromObject(object_msg.LocalLaserCuttingPrice.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class LaserEngravingPricesModel_Remote extends API.LaserEngravingPricesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {LaserEngravingPrices: Array<object_msg.LaserEngravingPrice>} {
    return { LaserEngravingPrices: [] };
  }

  getInfoState () {
    return { LaserEngravingPrices: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'price'], [4, 'max_cm2'], [8, 'approved_price'], [3, 'min_cm2'], [2, 'material'], [1, 'place_id'], [7, 'material_unified'], [6, 'currency']]) } };
  }

  getMutations () {
    const mutations = {
      add_LaserEngravingPrice (state: State, payload: object_msg.LaserEngravingPrice) {
        state.models.LaserEngravingPrices!.push(payload);
      },
      update_LaserEngravingPrice (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let LaserEngravingPrice of state.models.LaserEngravingPrices!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = LaserEngravingPrice[state.modelsInfo.LaserEngravingPrices.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            LaserEngravingPrice[state.modelsInfo.LaserEngravingPrices.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_LaserEngravingPrice (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.LaserEngravingPrices!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.LaserEngravingPrices![i][state.modelsInfo.LaserEngravingPrices.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.LaserEngravingPrices!.splice(i, 1);
          break;
        }
      },
      reset_all_LaserEngravingPrice (state: State, payload: object_msg.LaserEngravingPrice[]) {
        state.models.LaserEngravingPrices!.length = 0;
        Array.prototype.push.apply(state.models.LaserEngravingPrices, payload);
      },
      clear_all_LaserEngravingPrice (state: State) {
        state.models.LaserEngravingPrices!.length = 0;
      },
      set_valid_LaserEngravingPrice (state: State, payload: boolean) {
        state.modelsInfo.LaserEngravingPrices.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_LaserEngravingPrice', object_msg.LaserEngravingPrice.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_LaserEngravingPrice', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_LaserEngravingPrice', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_LaserEngravingPrice', object_msg.LaserEngravingPriceArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_LaserEngravingPrice');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_LaserEngravingPrice', valid);
  }

  static addMsgObject (dest: object_msg.LaserEngravingPrice): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.LaserEngravingPrice.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.LaserEngravingPrice): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.place_id }, { role: 2, s: src.material }, { role: 3, i: src.min_cm2 }, { role: 4, i: src.max_cm2 }] }) });
  }

  static updateMsgObject (src: object_msg.LaserEngravingPrice, dest: object_msg.LaserEngravingPrice): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.place_id }, { role: 2, s: src.material }, { role: 3, i: src.min_cm2 }, { role: 4, i: src.max_cm2 }];
    if (src.price !== dest.price) update.data.push({ role: 5, f: dest.price });
    if (src.max_cm2 !== dest.max_cm2) update.data.push({ role: 4, i: dest.max_cm2 });
    if (src.approved_price !== dest.approved_price) update.data.push({ role: 8, b: dest.approved_price });
    if (src.min_cm2 !== dest.min_cm2) update.data.push({ role: 3, i: dest.min_cm2 });
    if (src.material !== dest.material) update.data.push({ role: 2, s: dest.material });
    if (src.place_id.compare(dest.place_id) !== 0) update.data.push({ role: 1, u: dest.place_id });
    if (src.material_unified !== dest.material_unified) update.data.push({ role: 7, b: dest.material_unified });
    if (src.currency !== dest.currency) update.data.push({ role: 6, s: dest.currency });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.LaserEngravingPrice>, dest: Array<object_msg.LaserEngravingPrice>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.place_id.compare(dest[i].place_id) !== 0) continue;
        if (srcObj.material !== dest[i].material) continue;
        if (srcObj.min_cm2 !== dest[i].min_cm2) continue;
        if (srcObj.max_cm2 !== dest[i].max_cm2) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.LaserEngravingPrice): object_msg.LaserEngravingPrice { 
    return object_msg.LaserEngravingPrice.fromObject(object_msg.LaserEngravingPrice.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class LocalLaserEngravingPricesModel_Remote extends API.LocalLaserEngravingPricesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {LocalLaserEngravingPrices: Array<object_msg.LocalLaserEngravingPrice>} {
    return { LocalLaserEngravingPrices: [] };
  }

  getInfoState () {
    return { LocalLaserEngravingPrices: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'price'], [4, 'max_cm2'], [3, 'min_cm2'], [2, 'material'], [1, 'department_id'], [7, 'reference_place_id'], [6, 'currency']]) } };
  }

  getMutations () {
    const mutations = {
      add_LocalLaserEngravingPrice (state: State, payload: object_msg.LocalLaserEngravingPrice) {
        state.models.LocalLaserEngravingPrices!.push(payload);
      },
      update_LocalLaserEngravingPrice (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let LocalLaserEngravingPrice of state.models.LocalLaserEngravingPrices!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = LocalLaserEngravingPrice[state.modelsInfo.LocalLaserEngravingPrices.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            LocalLaserEngravingPrice[state.modelsInfo.LocalLaserEngravingPrices.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_LocalLaserEngravingPrice (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.LocalLaserEngravingPrices!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.LocalLaserEngravingPrices![i][state.modelsInfo.LocalLaserEngravingPrices.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.LocalLaserEngravingPrices!.splice(i, 1);
          break;
        }
      },
      reset_all_LocalLaserEngravingPrice (state: State, payload: object_msg.LocalLaserEngravingPrice[]) {
        state.models.LocalLaserEngravingPrices!.length = 0;
        Array.prototype.push.apply(state.models.LocalLaserEngravingPrices, payload);
      },
      clear_all_LocalLaserEngravingPrice (state: State) {
        state.models.LocalLaserEngravingPrices!.length = 0;
      },
      set_valid_LocalLaserEngravingPrice (state: State, payload: boolean) {
        state.modelsInfo.LocalLaserEngravingPrices.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_LocalLaserEngravingPrice', object_msg.LocalLaserEngravingPrice.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_LocalLaserEngravingPrice', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_LocalLaserEngravingPrice', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_LocalLaserEngravingPrice', object_msg.LocalLaserEngravingPriceArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_LocalLaserEngravingPrice');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_LocalLaserEngravingPrice', valid);
  }

  static addMsgObject (dest: object_msg.LocalLaserEngravingPrice): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.LocalLaserEngravingPrice.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.LocalLaserEngravingPrice): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.department_id }, { role: 2, s: src.material }, { role: 3, i: src.min_cm2 }, { role: 4, i: src.max_cm2 }] }) });
  }

  static updateMsgObject (src: object_msg.LocalLaserEngravingPrice, dest: object_msg.LocalLaserEngravingPrice): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.department_id }, { role: 2, s: src.material }, { role: 3, i: src.min_cm2 }, { role: 4, i: src.max_cm2 }];
    if (src.price !== dest.price) update.data.push({ role: 5, f: dest.price });
    if (src.max_cm2 !== dest.max_cm2) update.data.push({ role: 4, i: dest.max_cm2 });
    if (src.min_cm2 !== dest.min_cm2) update.data.push({ role: 3, i: dest.min_cm2 });
    if (src.material !== dest.material) update.data.push({ role: 2, s: dest.material });
    if (src.department_id.compare(dest.department_id) !== 0) update.data.push({ role: 1, u: dest.department_id });
    if (src.reference_place_id.compare(dest.reference_place_id) !== 0) update.data.push({ role: 7, u: dest.reference_place_id });
    if (src.currency !== dest.currency) update.data.push({ role: 6, s: dest.currency });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.LocalLaserEngravingPrice>, dest: Array<object_msg.LocalLaserEngravingPrice>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.department_id.compare(dest[i].department_id) !== 0) continue;
        if (srcObj.material !== dest[i].material) continue;
        if (srcObj.min_cm2 !== dest[i].min_cm2) continue;
        if (srcObj.max_cm2 !== dest[i].max_cm2) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.LocalLaserEngravingPrice): object_msg.LocalLaserEngravingPrice { 
    return object_msg.LocalLaserEngravingPrice.fromObject(object_msg.LocalLaserEngravingPrice.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Admin
// Row restriction based on role and user name:
// 
export class LogsModel_Remote extends API.LogsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {Logs: Array<object_msg.logEntry>} {
    return { Logs: [] };
  }

  getInfoState () {
    return { Logs: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'object'], [4, 'function'], [11, 'honeypot_error_type'], [10, 'ip'], [9, 'phone'], [8, 'raw_request'], [12, 'processed_request'], [3, 'message'], [2, 'level'], [1, 'time'], [7, 'pod'], [6, 'app']]) } };
  }

  getMutations () {
    const mutations = {
      add_logEntry (state: State, payload: object_msg.logEntry) {
        state.models.Logs!.push(payload);
      },
      update_logEntry (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let logEntry of state.models.Logs!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = logEntry[state.modelsInfo.Logs.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            logEntry[state.modelsInfo.Logs.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_logEntry (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.Logs!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.Logs![i][state.modelsInfo.Logs.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.Logs!.splice(i, 1);
          break;
        }
      },
      reset_all_logEntry (state: State, payload: object_msg.logEntry[]) {
        state.models.Logs!.length = 0;
        Array.prototype.push.apply(state.models.Logs, payload);
      },
      clear_all_logEntry (state: State) {
        state.models.Logs!.length = 0;
      },
      set_valid_logEntry (state: State, payload: boolean) {
        state.modelsInfo.Logs.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_logEntry', object_msg.logEntry.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_logEntry', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_logEntry', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_logEntry', object_msg.logEntryArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_logEntry');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_logEntry', valid);
  }

  static addMsgObject (dest: object_msg.logEntry): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.logEntry.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.logEntry): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, dt: src.time }, { role: 6, s: src.app }] }) });
  }

  static updateMsgObject (src: object_msg.logEntry, dest: object_msg.logEntry): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, dt: src.time }, { role: 6, s: src.app }];
    if (src.object !== dest.object) update.data.push({ role: 5, s: dest.object });
    if (src.function !== dest.function) update.data.push({ role: 4, s: dest.function });
    if (src.honeypot_error_type !== dest.honeypot_error_type) update.data.push({ role: 11, i: dest.honeypot_error_type });
    if (src.ip !== dest.ip) update.data.push({ role: 10, s: dest.ip });
    if (src.phone !== dest.phone) update.data.push({ role: 9, s: dest.phone });
    if (src.raw_request !== dest.raw_request) update.data.push({ role: 8, s: dest.raw_request });
    if (src.processed_request !== dest.processed_request) update.data.push({ role: 12, s: dest.processed_request });
    if (src.message !== dest.message) update.data.push({ role: 3, s: dest.message });
    if (src.level !== dest.level) update.data.push({ role: 2, i: dest.level });
    if (src.time.compare(dest.time) !== 0) update.data.push({ role: 1, dt: dest.time });
    if (src.pod !== dest.pod) update.data.push({ role: 7, s: dest.pod });
    if (src.app !== dest.app) update.data.push({ role: 6, s: dest.app });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.logEntry>, dest: Array<object_msg.logEntry>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.time.compare(dest[i].time) !== 0) continue;
        if (srcObj.app !== dest[i].app) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.logEntry): object_msg.logEntry { 
    return object_msg.logEntry.fromObject(object_msg.logEntry.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class CitiesRuModel_Remote extends API.CitiesRuModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {CitiesRu: Array<object_msg.CityRu>} {
    return { CitiesRu: [] };
  }

  getInfoState () {
    return { CitiesRu: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'population'], [3, 'region'], [2, 'name'], [1, 'id']]) } };
  }

  getMutations () {
    const mutations = {
      add_CityRu (state: State, payload: object_msg.CityRu) {
        state.models.CitiesRu!.push(payload);
      },
      update_CityRu (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let CityRu of state.models.CitiesRu!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = CityRu[state.modelsInfo.CitiesRu.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            CityRu[state.modelsInfo.CitiesRu.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_CityRu (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.CitiesRu!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.CitiesRu![i][state.modelsInfo.CitiesRu.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.CitiesRu!.splice(i, 1);
          break;
        }
      },
      reset_all_CityRu (state: State, payload: object_msg.CityRu[]) {
        state.models.CitiesRu!.length = 0;
        Array.prototype.push.apply(state.models.CitiesRu, payload);
      },
      clear_all_CityRu (state: State) {
        state.models.CitiesRu!.length = 0;
      },
      set_valid_CityRu (state: State, payload: boolean) {
        state.modelsInfo.CitiesRu.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_CityRu', object_msg.CityRu.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_CityRu', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_CityRu', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_CityRu', object_msg.CityRuArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_CityRu');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_CityRu', valid);
  }

  static addMsgObject (dest: object_msg.CityRu): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.CityRu.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.CityRu): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.CityRu, dest: object_msg.CityRu): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.population !== dest.population) update.data.push({ role: 5, i: dest.population });
    if (src.region !== dest.region) update.data.push({ role: 3, s: dest.region });
    if (src.name !== dest.name) update.data.push({ role: 2, s: dest.name });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.CityRu>, dest: Array<object_msg.CityRu>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.CityRu): object_msg.CityRu { 
    return object_msg.CityRu.fromObject(object_msg.CityRu.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class CityActionsModel_Remote extends API.CityActionsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {CityActions: Array<object_msg.CityAction>} {
    return { CityActions: [] };
  }

  getInfoState () {
    return { CityActions: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'city_id'], [2, 'action'], [1, 'place_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_CityAction (state: State, payload: object_msg.CityAction) {
        state.models.CityActions!.push(payload);
      },
      update_CityAction (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let CityAction of state.models.CityActions!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = CityAction[state.modelsInfo.CityActions.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            CityAction[state.modelsInfo.CityActions.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_CityAction (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.CityActions!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.CityActions![i][state.modelsInfo.CityActions.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.CityActions!.splice(i, 1);
          break;
        }
      },
      reset_all_CityAction (state: State, payload: object_msg.CityAction[]) {
        state.models.CityActions!.length = 0;
        Array.prototype.push.apply(state.models.CityActions, payload);
      },
      clear_all_CityAction (state: State) {
        state.models.CityActions!.length = 0;
      },
      set_valid_CityAction (state: State, payload: boolean) {
        state.modelsInfo.CityActions.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_CityAction', object_msg.CityAction.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_CityAction', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_CityAction', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_CityAction', object_msg.CityActionArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_CityAction');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_CityAction', valid);
  }

  static addMsgObject (dest: object_msg.CityAction): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.CityAction.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.CityAction): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.place_id }, { role: 2, s: src.action }] }) });
  }

  static updateMsgObject (src: object_msg.CityAction, dest: object_msg.CityAction): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.place_id }, { role: 2, s: src.action }];
    if (src.city_id.compare(dest.city_id) !== 0) update.data.push({ role: 5, u: dest.city_id });
    if (src.action !== dest.action) update.data.push({ role: 2, s: dest.action });
    if (src.place_id.compare(dest.place_id) !== 0) update.data.push({ role: 1, u: dest.place_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.CityAction>, dest: Array<object_msg.CityAction>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.place_id.compare(dest[i].place_id) !== 0) continue;
        if (srcObj.action !== dest[i].action) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.CityAction): object_msg.CityAction { 
    return object_msg.CityAction.fromObject(object_msg.CityAction.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrdersModel_Remote extends API.OrdersModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {Orders: Array<object_msg.Order>} {
    return { Orders: [] };
  }

  getInfoState () {
    return { Orders: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'creation_time'], [9, 'user_id'], [8, 'payment_organization_id'], [3, 'payment_by_individual'], [2, 'name'], [1, 'id'], [7, 'delivery_profile_id'], [6, 'delivery_time']]) } };
  }

  getMutations () {
    const mutations = {
      add_Order (state: State, payload: object_msg.Order) {
        state.models.Orders!.push(payload);
      },
      update_Order (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let Order of state.models.Orders!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = Order[state.modelsInfo.Orders.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            Order[state.modelsInfo.Orders.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_Order (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.Orders!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.Orders![i][state.modelsInfo.Orders.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.Orders!.splice(i, 1);
          break;
        }
      },
      reset_all_Order (state: State, payload: object_msg.Order[]) {
        state.models.Orders!.length = 0;
        Array.prototype.push.apply(state.models.Orders, payload);
      },
      clear_all_Order (state: State) {
        state.models.Orders!.length = 0;
      },
      set_valid_Order (state: State, payload: boolean) {
        state.modelsInfo.Orders.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_Order', object_msg.Order.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_Order', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_Order', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_Order', object_msg.OrderArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_Order');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_Order', valid);
  }

  static addMsgObject (dest: object_msg.Order): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.Order.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.Order): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.Order, dest: object_msg.Order): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.creation_time.compare(dest.creation_time) !== 0) update.data.push({ role: 5, dt: dest.creation_time });
    if (src.user_id.compare(dest.user_id) !== 0) update.data.push({ role: 9, u: dest.user_id });
    if (src.payment_organization_id.compare(dest.payment_organization_id) !== 0) update.data.push({ role: 8, u: dest.payment_organization_id });
    if (src.payment_by_individual !== dest.payment_by_individual) update.data.push({ role: 3, b: dest.payment_by_individual });
    if (src.name !== dest.name) update.data.push({ role: 2, s: dest.name });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    if (src.delivery_profile_id.compare(dest.delivery_profile_id) !== 0) update.data.push({ role: 7, u: dest.delivery_profile_id });
    if (src.delivery_time.compare(dest.delivery_time) !== 0) update.data.push({ role: 6, dt: dest.delivery_time });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.Order>, dest: Array<object_msg.Order>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.Order): object_msg.Order { 
    return object_msg.Order.fromObject(object_msg.Order.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrderStatusesModel_Remote extends API.OrderStatusesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrderStatuses: Array<object_msg.OrderStatus>} {
    return { OrderStatuses: [] };
  }

  getInfoState () {
    return { OrderStatuses: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'creation_time'], [2, 'status'], [1, 'order_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrderStatus (state: State, payload: object_msg.OrderStatus) {
        state.models.OrderStatuses!.push(payload);
      },
      update_OrderStatus (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrderStatus of state.models.OrderStatuses!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrderStatus[state.modelsInfo.OrderStatuses.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrderStatus[state.modelsInfo.OrderStatuses.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrderStatus (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrderStatuses!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrderStatuses![i][state.modelsInfo.OrderStatuses.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrderStatuses!.splice(i, 1);
          break;
        }
      },
      reset_all_OrderStatus (state: State, payload: object_msg.OrderStatus[]) {
        state.models.OrderStatuses!.length = 0;
        Array.prototype.push.apply(state.models.OrderStatuses, payload);
      },
      clear_all_OrderStatus (state: State) {
        state.models.OrderStatuses!.length = 0;
      },
      set_valid_OrderStatus (state: State, payload: boolean) {
        state.modelsInfo.OrderStatuses.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrderStatus', object_msg.OrderStatus.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrderStatus', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrderStatus', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrderStatus', object_msg.OrderStatusArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrderStatus');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrderStatus', valid);
  }

  static addMsgObject (dest: object_msg.OrderStatus): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrderStatus.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrderStatus): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 5, dt: src.creation_time }] }) });
  }

  static updateMsgObject (src: object_msg.OrderStatus, dest: object_msg.OrderStatus): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 5, dt: src.creation_time }];
    if (src.creation_time.compare(dest.creation_time) !== 0) update.data.push({ role: 5, dt: dest.creation_time });
    if (src.status !== dest.status) update.data.push({ role: 2, s: dest.status });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrderStatus>, dest: Array<object_msg.OrderStatus>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.creation_time.compare(dest[i].creation_time) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrderStatus): object_msg.OrderStatus { 
    return object_msg.OrderStatus.fromObject(object_msg.OrderStatus.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrderCommentsModel_Remote extends API.OrderCommentsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrderComments: Array<object_msg.OrderComment>} {
    return { OrderComments: [] };
  }

  getInfoState () {
    return { OrderComments: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'creation_time'], [3, 'author_name'], [2, 'comment'], [1, 'order_id'], [6, 'user_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrderComment (state: State, payload: object_msg.OrderComment) {
        state.models.OrderComments!.push(payload);
      },
      update_OrderComment (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrderComment of state.models.OrderComments!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrderComment[state.modelsInfo.OrderComments.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrderComment[state.modelsInfo.OrderComments.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrderComment (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrderComments!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrderComments![i][state.modelsInfo.OrderComments.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrderComments!.splice(i, 1);
          break;
        }
      },
      reset_all_OrderComment (state: State, payload: object_msg.OrderComment[]) {
        state.models.OrderComments!.length = 0;
        Array.prototype.push.apply(state.models.OrderComments, payload);
      },
      clear_all_OrderComment (state: State) {
        state.models.OrderComments!.length = 0;
      },
      set_valid_OrderComment (state: State, payload: boolean) {
        state.modelsInfo.OrderComments.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrderComment', object_msg.OrderComment.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrderComment', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrderComment', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrderComment', object_msg.OrderCommentArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrderComment');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrderComment', valid);
  }

  static addMsgObject (dest: object_msg.OrderComment): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrderComment.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrderComment): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 5, dt: src.creation_time }] }) });
  }

  static updateMsgObject (src: object_msg.OrderComment, dest: object_msg.OrderComment): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 5, dt: src.creation_time }];
    if (src.creation_time.compare(dest.creation_time) !== 0) update.data.push({ role: 5, dt: dest.creation_time });
    if (src.author_name !== dest.author_name) update.data.push({ role: 3, s: dest.author_name });
    if (src.comment !== dest.comment) update.data.push({ role: 2, s: dest.comment });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    if (src.user_id.compare(dest.user_id) !== 0) update.data.push({ role: 6, u: dest.user_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrderComment>, dest: Array<object_msg.OrderComment>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.creation_time.compare(dest[i].creation_time) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrderComment): object_msg.OrderComment { 
    return object_msg.OrderComment.fromObject(object_msg.OrderComment.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrderCommentFilesModel_Remote extends API.OrderCommentFilesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrderCommentFiles: Array<object_msg.OrderCommentFile>} {
    return { OrderCommentFiles: [] };
  }

  getInfoState () {
    return { OrderCommentFiles: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'comment_creation_time'], [1, 'order_id'], [6, 'file_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrderCommentFile (state: State, payload: object_msg.OrderCommentFile) {
        state.models.OrderCommentFiles!.push(payload);
      },
      update_OrderCommentFile (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrderCommentFile of state.models.OrderCommentFiles!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrderCommentFile[state.modelsInfo.OrderCommentFiles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrderCommentFile[state.modelsInfo.OrderCommentFiles.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrderCommentFile (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrderCommentFiles!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrderCommentFiles![i][state.modelsInfo.OrderCommentFiles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrderCommentFiles!.splice(i, 1);
          break;
        }
      },
      reset_all_OrderCommentFile (state: State, payload: object_msg.OrderCommentFile[]) {
        state.models.OrderCommentFiles!.length = 0;
        Array.prototype.push.apply(state.models.OrderCommentFiles, payload);
      },
      clear_all_OrderCommentFile (state: State) {
        state.models.OrderCommentFiles!.length = 0;
      },
      set_valid_OrderCommentFile (state: State, payload: boolean) {
        state.modelsInfo.OrderCommentFiles.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrderCommentFile', object_msg.OrderCommentFile.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrderCommentFile', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrderCommentFile', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrderCommentFile', object_msg.OrderCommentFileArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrderCommentFile');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrderCommentFile', valid);
  }

  static addMsgObject (dest: object_msg.OrderCommentFile): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrderCommentFile.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrderCommentFile): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 5, dt: src.comment_creation_time }, { role: 6, u: src.file_id }] }) });
  }

  static updateMsgObject (src: object_msg.OrderCommentFile, dest: object_msg.OrderCommentFile): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 5, dt: src.comment_creation_time }, { role: 6, u: src.file_id }];
    if (src.comment_creation_time.compare(dest.comment_creation_time) !== 0) update.data.push({ role: 5, dt: dest.comment_creation_time });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    if (src.file_id.compare(dest.file_id) !== 0) update.data.push({ role: 6, u: dest.file_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrderCommentFile>, dest: Array<object_msg.OrderCommentFile>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.comment_creation_time.compare(dest[i].comment_creation_time) !== 0) continue;
        if (srcObj.file_id.compare(dest[i].file_id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrderCommentFile): object_msg.OrderCommentFile { 
    return object_msg.OrderCommentFile.fromObject(object_msg.OrderCommentFile.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class UserDeliveryProfilesModel_Remote extends API.UserDeliveryProfilesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {UserDeliveryProfiles: Array<object_msg.UserDeliveryProfile>} {
    return { UserDeliveryProfiles: [] };
  }

  getInfoState () {
    return { UserDeliveryProfiles: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'user_id'], [4, 'phone'], [11, 'archive'], [10, 'address_quality'], [9, 'longitude'], [8, 'latitude'], [3, 'address'], [2, 'contact_person'], [1, 'id'], [7, 'town'], [6, 'last_use_time']]) } };
  }

  getMutations () {
    const mutations = {
      add_UserDeliveryProfile (state: State, payload: object_msg.UserDeliveryProfile) {
        state.models.UserDeliveryProfiles!.push(payload);
      },
      update_UserDeliveryProfile (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let UserDeliveryProfile of state.models.UserDeliveryProfiles!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = UserDeliveryProfile[state.modelsInfo.UserDeliveryProfiles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            UserDeliveryProfile[state.modelsInfo.UserDeliveryProfiles.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_UserDeliveryProfile (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.UserDeliveryProfiles!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.UserDeliveryProfiles![i][state.modelsInfo.UserDeliveryProfiles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.UserDeliveryProfiles!.splice(i, 1);
          break;
        }
      },
      reset_all_UserDeliveryProfile (state: State, payload: object_msg.UserDeliveryProfile[]) {
        state.models.UserDeliveryProfiles!.length = 0;
        Array.prototype.push.apply(state.models.UserDeliveryProfiles, payload);
      },
      clear_all_UserDeliveryProfile (state: State) {
        state.models.UserDeliveryProfiles!.length = 0;
      },
      set_valid_UserDeliveryProfile (state: State, payload: boolean) {
        state.modelsInfo.UserDeliveryProfiles.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_UserDeliveryProfile', object_msg.UserDeliveryProfile.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_UserDeliveryProfile', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_UserDeliveryProfile', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_UserDeliveryProfile', object_msg.UserDeliveryProfileArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_UserDeliveryProfile');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_UserDeliveryProfile', valid);
  }

  static addMsgObject (dest: object_msg.UserDeliveryProfile): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.UserDeliveryProfile.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.UserDeliveryProfile): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.UserDeliveryProfile, dest: object_msg.UserDeliveryProfile): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.user_id.compare(dest.user_id) !== 0) update.data.push({ role: 5, u: dest.user_id });
    if (src.phone !== dest.phone) update.data.push({ role: 4, s: dest.phone });
    if (src.archive !== dest.archive) update.data.push({ role: 11, b: dest.archive });
    if (src.address_quality !== dest.address_quality) update.data.push({ role: 10, i: dest.address_quality });
    if (src.longitude !== dest.longitude) update.data.push({ role: 9, f: dest.longitude });
    if (src.latitude !== dest.latitude) update.data.push({ role: 8, f: dest.latitude });
    if (src.address !== dest.address) update.data.push({ role: 3, s: dest.address });
    if (src.contact_person !== dest.contact_person) update.data.push({ role: 2, s: dest.contact_person });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    if (src.town !== dest.town) update.data.push({ role: 7, s: dest.town });
    if (src.last_use_time.compare(dest.last_use_time) !== 0) update.data.push({ role: 6, dt: dest.last_use_time });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.UserDeliveryProfile>, dest: Array<object_msg.UserDeliveryProfile>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.UserDeliveryProfile): object_msg.UserDeliveryProfile { 
    return object_msg.UserDeliveryProfile.fromObject(object_msg.UserDeliveryProfile.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrderStagesModel_Remote extends API.OrderStagesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrderStages: Array<object_msg.OrderStage>} {
    return { OrderStages: [] };
  }

  getInfoState () {
    return { OrderStages: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'place_id'], [4, 'action'], [11, 'in_predefined_range'], [10, 'approximate_price'], [9, 'price'], [8, 'manager_user_id'], [3, 'name'], [2, 'stage_number'], [1, 'order_id'], [7, 'approved_by_manager'], [6, 'clients_material']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrderStage (state: State, payload: object_msg.OrderStage) {
        state.models.OrderStages!.push(payload);
      },
      update_OrderStage (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrderStage of state.models.OrderStages!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrderStage[state.modelsInfo.OrderStages.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrderStage[state.modelsInfo.OrderStages.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrderStage (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrderStages!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrderStages![i][state.modelsInfo.OrderStages.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrderStages!.splice(i, 1);
          break;
        }
      },
      reset_all_OrderStage (state: State, payload: object_msg.OrderStage[]) {
        state.models.OrderStages!.length = 0;
        Array.prototype.push.apply(state.models.OrderStages, payload);
      },
      clear_all_OrderStage (state: State) {
        state.models.OrderStages!.length = 0;
      },
      set_valid_OrderStage (state: State, payload: boolean) {
        state.modelsInfo.OrderStages.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrderStage', object_msg.OrderStage.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrderStage', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrderStage', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrderStage', object_msg.OrderStageArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrderStage');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrderStage', valid);
  }

  static addMsgObject (dest: object_msg.OrderStage): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrderStage.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrderStage): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }] }) });
  }

  static updateMsgObject (src: object_msg.OrderStage, dest: object_msg.OrderStage): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }];
    if (src.place_id.compare(dest.place_id) !== 0) update.data.push({ role: 5, u: dest.place_id });
    if (src.action !== dest.action) update.data.push({ role: 4, s: dest.action });
    if (src.in_predefined_range !== dest.in_predefined_range) update.data.push({ role: 11, b: dest.in_predefined_range });
    if (src.approximate_price !== dest.approximate_price) update.data.push({ role: 10, f: dest.approximate_price });
    if (src.price !== dest.price) update.data.push({ role: 9, f: dest.price });
    if (src.manager_user_id.compare(dest.manager_user_id) !== 0) update.data.push({ role: 8, u: dest.manager_user_id });
    if (src.name !== dest.name) update.data.push({ role: 3, s: dest.name });
    if (src.stage_number !== dest.stage_number) update.data.push({ role: 2, i: dest.stage_number });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    if (src.approved_by_manager !== dest.approved_by_manager) update.data.push({ role: 7, b: dest.approved_by_manager });
    if (src.clients_material !== dest.clients_material) update.data.push({ role: 6, b: dest.clients_material });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrderStage>, dest: Array<object_msg.OrderStage>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.stage_number !== dest[i].stage_number) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrderStage): object_msg.OrderStage { 
    return object_msg.OrderStage.fromObject(object_msg.OrderStage.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrderStageStatusesModel_Remote extends API.OrderStageStatusesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrderStageStatuses: Array<object_msg.OrderStageStatus>} {
    return { OrderStageStatuses: [] };
  }

  getInfoState () {
    return { OrderStageStatuses: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'creation_time'], [3, 'status'], [2, 'stage_number'], [1, 'order_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrderStageStatus (state: State, payload: object_msg.OrderStageStatus) {
        state.models.OrderStageStatuses!.push(payload);
      },
      update_OrderStageStatus (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrderStageStatus of state.models.OrderStageStatuses!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrderStageStatus[state.modelsInfo.OrderStageStatuses.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrderStageStatus[state.modelsInfo.OrderStageStatuses.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrderStageStatus (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrderStageStatuses!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrderStageStatuses![i][state.modelsInfo.OrderStageStatuses.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrderStageStatuses!.splice(i, 1);
          break;
        }
      },
      reset_all_OrderStageStatus (state: State, payload: object_msg.OrderStageStatus[]) {
        state.models.OrderStageStatuses!.length = 0;
        Array.prototype.push.apply(state.models.OrderStageStatuses, payload);
      },
      clear_all_OrderStageStatus (state: State) {
        state.models.OrderStageStatuses!.length = 0;
      },
      set_valid_OrderStageStatus (state: State, payload: boolean) {
        state.modelsInfo.OrderStageStatuses.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrderStageStatus', object_msg.OrderStageStatus.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrderStageStatus', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrderStageStatus', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrderStageStatus', object_msg.OrderStageStatusArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrderStageStatus');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrderStageStatus', valid);
  }

  static addMsgObject (dest: object_msg.OrderStageStatus): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrderStageStatus.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrderStageStatus): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }, { role: 5, dt: src.creation_time }] }) });
  }

  static updateMsgObject (src: object_msg.OrderStageStatus, dest: object_msg.OrderStageStatus): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }, { role: 5, dt: src.creation_time }];
    if (src.creation_time.compare(dest.creation_time) !== 0) update.data.push({ role: 5, dt: dest.creation_time });
    if (src.status !== dest.status) update.data.push({ role: 3, s: dest.status });
    if (src.stage_number !== dest.stage_number) update.data.push({ role: 2, i: dest.stage_number });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrderStageStatus>, dest: Array<object_msg.OrderStageStatus>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.stage_number !== dest[i].stage_number) continue;
        if (srcObj.creation_time.compare(dest[i].creation_time) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrderStageStatus): object_msg.OrderStageStatus { 
    return object_msg.OrderStageStatus.fromObject(object_msg.OrderStageStatus.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrderStageDeliveryProfilesModel_Remote extends API.OrderStageDeliveryProfilesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrderStageDeliveryProfiles: Array<object_msg.OrderStageDeliveryProfile>} {
    return { OrderStageDeliveryProfiles: [] };
  }

  getInfoState () {
    return { OrderStageDeliveryProfiles: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'address'], [4, 'phone'], [3, 'contact_person'], [2, 'stage_number'], [1, 'order_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrderStageDeliveryProfile (state: State, payload: object_msg.OrderStageDeliveryProfile) {
        state.models.OrderStageDeliveryProfiles!.push(payload);
      },
      update_OrderStageDeliveryProfile (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrderStageDeliveryProfile of state.models.OrderStageDeliveryProfiles!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrderStageDeliveryProfile[state.modelsInfo.OrderStageDeliveryProfiles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrderStageDeliveryProfile[state.modelsInfo.OrderStageDeliveryProfiles.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrderStageDeliveryProfile (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrderStageDeliveryProfiles!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrderStageDeliveryProfiles![i][state.modelsInfo.OrderStageDeliveryProfiles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrderStageDeliveryProfiles!.splice(i, 1);
          break;
        }
      },
      reset_all_OrderStageDeliveryProfile (state: State, payload: object_msg.OrderStageDeliveryProfile[]) {
        state.models.OrderStageDeliveryProfiles!.length = 0;
        Array.prototype.push.apply(state.models.OrderStageDeliveryProfiles, payload);
      },
      clear_all_OrderStageDeliveryProfile (state: State) {
        state.models.OrderStageDeliveryProfiles!.length = 0;
      },
      set_valid_OrderStageDeliveryProfile (state: State, payload: boolean) {
        state.modelsInfo.OrderStageDeliveryProfiles.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrderStageDeliveryProfile', object_msg.OrderStageDeliveryProfile.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrderStageDeliveryProfile', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrderStageDeliveryProfile', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrderStageDeliveryProfile', object_msg.OrderStageDeliveryProfileArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrderStageDeliveryProfile');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrderStageDeliveryProfile', valid);
  }

  static addMsgObject (dest: object_msg.OrderStageDeliveryProfile): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrderStageDeliveryProfile.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrderStageDeliveryProfile): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }] }) });
  }

  static updateMsgObject (src: object_msg.OrderStageDeliveryProfile, dest: object_msg.OrderStageDeliveryProfile): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }];
    if (src.address !== dest.address) update.data.push({ role: 5, s: dest.address });
    if (src.phone !== dest.phone) update.data.push({ role: 4, s: dest.phone });
    if (src.contact_person !== dest.contact_person) update.data.push({ role: 3, s: dest.contact_person });
    if (src.stage_number !== dest.stage_number) update.data.push({ role: 2, i: dest.stage_number });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrderStageDeliveryProfile>, dest: Array<object_msg.OrderStageDeliveryProfile>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.stage_number !== dest[i].stage_number) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrderStageDeliveryProfile): object_msg.OrderStageDeliveryProfile { 
    return object_msg.OrderStageDeliveryProfile.fromObject(object_msg.OrderStageDeliveryProfile.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrderStageCommentsModel_Remote extends API.OrderStageCommentsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrderStageComments: Array<object_msg.OrderStageComment>} {
    return { OrderStageComments: [] };
  }

  getInfoState () {
    return { OrderStageComments: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'creation_time'], [4, 'author_name'], [3, 'comment'], [2, 'stage_number'], [1, 'order_id'], [6, 'user_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrderStageComment (state: State, payload: object_msg.OrderStageComment) {
        state.models.OrderStageComments!.push(payload);
      },
      update_OrderStageComment (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrderStageComment of state.models.OrderStageComments!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrderStageComment[state.modelsInfo.OrderStageComments.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrderStageComment[state.modelsInfo.OrderStageComments.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrderStageComment (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrderStageComments!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrderStageComments![i][state.modelsInfo.OrderStageComments.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrderStageComments!.splice(i, 1);
          break;
        }
      },
      reset_all_OrderStageComment (state: State, payload: object_msg.OrderStageComment[]) {
        state.models.OrderStageComments!.length = 0;
        Array.prototype.push.apply(state.models.OrderStageComments, payload);
      },
      clear_all_OrderStageComment (state: State) {
        state.models.OrderStageComments!.length = 0;
      },
      set_valid_OrderStageComment (state: State, payload: boolean) {
        state.modelsInfo.OrderStageComments.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrderStageComment', object_msg.OrderStageComment.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrderStageComment', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrderStageComment', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrderStageComment', object_msg.OrderStageCommentArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrderStageComment');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrderStageComment', valid);
  }

  static addMsgObject (dest: object_msg.OrderStageComment): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrderStageComment.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrderStageComment): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }, { role: 5, dt: src.creation_time }] }) });
  }

  static updateMsgObject (src: object_msg.OrderStageComment, dest: object_msg.OrderStageComment): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }, { role: 5, dt: src.creation_time }];
    if (src.creation_time.compare(dest.creation_time) !== 0) update.data.push({ role: 5, dt: dest.creation_time });
    if (src.author_name !== dest.author_name) update.data.push({ role: 4, s: dest.author_name });
    if (src.comment !== dest.comment) update.data.push({ role: 3, s: dest.comment });
    if (src.stage_number !== dest.stage_number) update.data.push({ role: 2, i: dest.stage_number });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    if (src.user_id.compare(dest.user_id) !== 0) update.data.push({ role: 6, u: dest.user_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrderStageComment>, dest: Array<object_msg.OrderStageComment>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.stage_number !== dest[i].stage_number) continue;
        if (srcObj.creation_time.compare(dest[i].creation_time) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrderStageComment): object_msg.OrderStageComment { 
    return object_msg.OrderStageComment.fromObject(object_msg.OrderStageComment.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrderStageCommentFilesModel_Remote extends API.OrderStageCommentFilesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrderStageCommentFiles: Array<object_msg.OrderStageCommentFile>} {
    return { OrderStageCommentFiles: [] };
  }

  getInfoState () {
    return { OrderStageCommentFiles: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'comment_creation_time'], [2, 'stage_number'], [1, 'order_id'], [6, 'file_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrderStageCommentFile (state: State, payload: object_msg.OrderStageCommentFile) {
        state.models.OrderStageCommentFiles!.push(payload);
      },
      update_OrderStageCommentFile (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrderStageCommentFile of state.models.OrderStageCommentFiles!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrderStageCommentFile[state.modelsInfo.OrderStageCommentFiles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrderStageCommentFile[state.modelsInfo.OrderStageCommentFiles.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrderStageCommentFile (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrderStageCommentFiles!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrderStageCommentFiles![i][state.modelsInfo.OrderStageCommentFiles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrderStageCommentFiles!.splice(i, 1);
          break;
        }
      },
      reset_all_OrderStageCommentFile (state: State, payload: object_msg.OrderStageCommentFile[]) {
        state.models.OrderStageCommentFiles!.length = 0;
        Array.prototype.push.apply(state.models.OrderStageCommentFiles, payload);
      },
      clear_all_OrderStageCommentFile (state: State) {
        state.models.OrderStageCommentFiles!.length = 0;
      },
      set_valid_OrderStageCommentFile (state: State, payload: boolean) {
        state.modelsInfo.OrderStageCommentFiles.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrderStageCommentFile', object_msg.OrderStageCommentFile.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrderStageCommentFile', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrderStageCommentFile', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrderStageCommentFile', object_msg.OrderStageCommentFileArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrderStageCommentFile');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrderStageCommentFile', valid);
  }

  static addMsgObject (dest: object_msg.OrderStageCommentFile): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrderStageCommentFile.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrderStageCommentFile): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }, { role: 5, dt: src.comment_creation_time }, { role: 6, u: src.file_id }] }) });
  }

  static updateMsgObject (src: object_msg.OrderStageCommentFile, dest: object_msg.OrderStageCommentFile): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }, { role: 5, dt: src.comment_creation_time }, { role: 6, u: src.file_id }];
    if (src.comment_creation_time.compare(dest.comment_creation_time) !== 0) update.data.push({ role: 5, dt: dest.comment_creation_time });
    if (src.stage_number !== dest.stage_number) update.data.push({ role: 2, i: dest.stage_number });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    if (src.file_id.compare(dest.file_id) !== 0) update.data.push({ role: 6, u: dest.file_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrderStageCommentFile>, dest: Array<object_msg.OrderStageCommentFile>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.stage_number !== dest[i].stage_number) continue;
        if (srcObj.comment_creation_time.compare(dest[i].comment_creation_time) !== 0) continue;
        if (srcObj.file_id.compare(dest[i].file_id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrderStageCommentFile): object_msg.OrderStageCommentFile { 
    return object_msg.OrderStageCommentFile.fromObject(object_msg.OrderStageCommentFile.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class UploadedFilesModel_Remote extends API.UploadedFilesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {UploadedFiles: Array<object_msg.UploadedFile>} {
    return { UploadedFiles: [] };
  }

  getInfoState () {
    return { UploadedFiles: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'upload_time'], [2, 'original_name'], [1, 'id'], [6, 'owner_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_UploadedFile (state: State, payload: object_msg.UploadedFile) {
        state.models.UploadedFiles!.push(payload);
      },
      update_UploadedFile (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let UploadedFile of state.models.UploadedFiles!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = UploadedFile[state.modelsInfo.UploadedFiles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            UploadedFile[state.modelsInfo.UploadedFiles.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_UploadedFile (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.UploadedFiles!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.UploadedFiles![i][state.modelsInfo.UploadedFiles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.UploadedFiles!.splice(i, 1);
          break;
        }
      },
      reset_all_UploadedFile (state: State, payload: object_msg.UploadedFile[]) {
        state.models.UploadedFiles!.length = 0;
        Array.prototype.push.apply(state.models.UploadedFiles, payload);
      },
      clear_all_UploadedFile (state: State) {
        state.models.UploadedFiles!.length = 0;
      },
      set_valid_UploadedFile (state: State, payload: boolean) {
        state.modelsInfo.UploadedFiles.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_UploadedFile', object_msg.UploadedFile.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_UploadedFile', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_UploadedFile', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_UploadedFile', object_msg.UploadedFileArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_UploadedFile');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_UploadedFile', valid);
  }

  static addMsgObject (dest: object_msg.UploadedFile): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.UploadedFile.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.UploadedFile): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.UploadedFile, dest: object_msg.UploadedFile): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.upload_time.compare(dest.upload_time) !== 0) update.data.push({ role: 5, dt: dest.upload_time });
    if (src.original_name !== dest.original_name) update.data.push({ role: 2, s: dest.original_name });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    if (src.owner_id.compare(dest.owner_id) !== 0) update.data.push({ role: 6, u: dest.owner_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.UploadedFile>, dest: Array<object_msg.UploadedFile>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.UploadedFile): object_msg.UploadedFile { 
    return object_msg.UploadedFile.fromObject(object_msg.UploadedFile.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class CommercialOfferRequestsModel_Remote extends API.CommercialOfferRequestsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {CommercialOfferRequests: Array<object_msg.CommercialOfferRequest>} {
    return { CommercialOfferRequests: [] };
  }

  getInfoState () {
    return { CommercialOfferRequests: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'place_id'], [4, 'communication_type'], [3, 'receiver'], [2, 'stage_number'], [1, 'order_id'], [7, 'reply_time'], [6, 'send_time']]) } };
  }

  getMutations () {
    const mutations = {
      add_CommercialOfferRequest (state: State, payload: object_msg.CommercialOfferRequest) {
        state.models.CommercialOfferRequests!.push(payload);
      },
      update_CommercialOfferRequest (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let CommercialOfferRequest of state.models.CommercialOfferRequests!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = CommercialOfferRequest[state.modelsInfo.CommercialOfferRequests.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            CommercialOfferRequest[state.modelsInfo.CommercialOfferRequests.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_CommercialOfferRequest (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.CommercialOfferRequests!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.CommercialOfferRequests![i][state.modelsInfo.CommercialOfferRequests.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.CommercialOfferRequests!.splice(i, 1);
          break;
        }
      },
      reset_all_CommercialOfferRequest (state: State, payload: object_msg.CommercialOfferRequest[]) {
        state.models.CommercialOfferRequests!.length = 0;
        Array.prototype.push.apply(state.models.CommercialOfferRequests, payload);
      },
      clear_all_CommercialOfferRequest (state: State) {
        state.models.CommercialOfferRequests!.length = 0;
      },
      set_valid_CommercialOfferRequest (state: State, payload: boolean) {
        state.modelsInfo.CommercialOfferRequests.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_CommercialOfferRequest', object_msg.CommercialOfferRequest.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_CommercialOfferRequest', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_CommercialOfferRequest', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_CommercialOfferRequest', object_msg.CommercialOfferRequestArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_CommercialOfferRequest');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_CommercialOfferRequest', valid);
  }

  static addMsgObject (dest: object_msg.CommercialOfferRequest): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.CommercialOfferRequest.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.CommercialOfferRequest): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }, { role: 5, u: src.place_id }, { role: 6, dt: src.send_time }] }) });
  }

  static updateMsgObject (src: object_msg.CommercialOfferRequest, dest: object_msg.CommercialOfferRequest): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }, { role: 5, u: src.place_id }, { role: 6, dt: src.send_time }];
    if (src.place_id.compare(dest.place_id) !== 0) update.data.push({ role: 5, u: dest.place_id });
    if (src.communication_type !== dest.communication_type) update.data.push({ role: 4, s: dest.communication_type });
    if (src.receiver !== dest.receiver) update.data.push({ role: 3, s: dest.receiver });
    if (src.stage_number !== dest.stage_number) update.data.push({ role: 2, i: dest.stage_number });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    if (src.reply_time.compare(dest.reply_time) !== 0) update.data.push({ role: 7, dt: dest.reply_time });
    if (src.send_time.compare(dest.send_time) !== 0) update.data.push({ role: 6, dt: dest.send_time });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.CommercialOfferRequest>, dest: Array<object_msg.CommercialOfferRequest>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.stage_number !== dest[i].stage_number) continue;
        if (srcObj.place_id.compare(dest[i].place_id) !== 0) continue;
        if (srcObj.send_time.compare(dest[i].send_time) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.CommercialOfferRequest): object_msg.CommercialOfferRequest { 
    return object_msg.CommercialOfferRequest.fromObject(object_msg.CommercialOfferRequest.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class LaserCuttingStagesModel_Remote extends API.LaserCuttingStagesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {LaserCuttingStages: Array<object_msg.LaserCuttingStage>} {
    return { LaserCuttingStages: [] };
  }

  getInfoState () {
    return { LaserCuttingStages: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'meters'], [4, 'thickness_01mm'], [3, 'material'], [2, 'stage_number'], [1, 'order_id'], [6, 'material_unified']]) } };
  }

  getMutations () {
    const mutations = {
      add_LaserCuttingStage (state: State, payload: object_msg.LaserCuttingStage) {
        state.models.LaserCuttingStages!.push(payload);
      },
      update_LaserCuttingStage (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let LaserCuttingStage of state.models.LaserCuttingStages!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = LaserCuttingStage[state.modelsInfo.LaserCuttingStages.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            LaserCuttingStage[state.modelsInfo.LaserCuttingStages.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_LaserCuttingStage (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.LaserCuttingStages!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.LaserCuttingStages![i][state.modelsInfo.LaserCuttingStages.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.LaserCuttingStages!.splice(i, 1);
          break;
        }
      },
      reset_all_LaserCuttingStage (state: State, payload: object_msg.LaserCuttingStage[]) {
        state.models.LaserCuttingStages!.length = 0;
        Array.prototype.push.apply(state.models.LaserCuttingStages, payload);
      },
      clear_all_LaserCuttingStage (state: State) {
        state.models.LaserCuttingStages!.length = 0;
      },
      set_valid_LaserCuttingStage (state: State, payload: boolean) {
        state.modelsInfo.LaserCuttingStages.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_LaserCuttingStage', object_msg.LaserCuttingStage.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_LaserCuttingStage', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_LaserCuttingStage', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_LaserCuttingStage', object_msg.LaserCuttingStageArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_LaserCuttingStage');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_LaserCuttingStage', valid);
  }

  static addMsgObject (dest: object_msg.LaserCuttingStage): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.LaserCuttingStage.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.LaserCuttingStage): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }] }) });
  }

  static updateMsgObject (src: object_msg.LaserCuttingStage, dest: object_msg.LaserCuttingStage): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }];
    if (src.meters !== dest.meters) update.data.push({ role: 5, i: dest.meters });
    if (src.thickness_01mm !== dest.thickness_01mm) update.data.push({ role: 4, i: dest.thickness_01mm });
    if (src.material !== dest.material) update.data.push({ role: 3, s: dest.material });
    if (src.stage_number !== dest.stage_number) update.data.push({ role: 2, i: dest.stage_number });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    if (src.material_unified !== dest.material_unified) update.data.push({ role: 6, b: dest.material_unified });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.LaserCuttingStage>, dest: Array<object_msg.LaserCuttingStage>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.stage_number !== dest[i].stage_number) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.LaserCuttingStage): object_msg.LaserCuttingStage { 
    return object_msg.LaserCuttingStage.fromObject(object_msg.LaserCuttingStage.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class LaserEngravingStagesModel_Remote extends API.LaserEngravingStagesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {LaserEngravingStages: Array<object_msg.LaserEngravingStage>} {
    return { LaserEngravingStages: [] };
  }

  getInfoState () {
    return { LaserEngravingStages: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'material_unified'], [4, 'centimeters2'], [3, 'material'], [2, 'stage_number'], [1, 'order_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_LaserEngravingStage (state: State, payload: object_msg.LaserEngravingStage) {
        state.models.LaserEngravingStages!.push(payload);
      },
      update_LaserEngravingStage (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let LaserEngravingStage of state.models.LaserEngravingStages!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = LaserEngravingStage[state.modelsInfo.LaserEngravingStages.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            LaserEngravingStage[state.modelsInfo.LaserEngravingStages.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_LaserEngravingStage (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.LaserEngravingStages!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.LaserEngravingStages![i][state.modelsInfo.LaserEngravingStages.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.LaserEngravingStages!.splice(i, 1);
          break;
        }
      },
      reset_all_LaserEngravingStage (state: State, payload: object_msg.LaserEngravingStage[]) {
        state.models.LaserEngravingStages!.length = 0;
        Array.prototype.push.apply(state.models.LaserEngravingStages, payload);
      },
      clear_all_LaserEngravingStage (state: State) {
        state.models.LaserEngravingStages!.length = 0;
      },
      set_valid_LaserEngravingStage (state: State, payload: boolean) {
        state.modelsInfo.LaserEngravingStages.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_LaserEngravingStage', object_msg.LaserEngravingStage.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_LaserEngravingStage', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_LaserEngravingStage', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_LaserEngravingStage', object_msg.LaserEngravingStageArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_LaserEngravingStage');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_LaserEngravingStage', valid);
  }

  static addMsgObject (dest: object_msg.LaserEngravingStage): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.LaserEngravingStage.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.LaserEngravingStage): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }] }) });
  }

  static updateMsgObject (src: object_msg.LaserEngravingStage, dest: object_msg.LaserEngravingStage): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.order_id }, { role: 2, i: src.stage_number }];
    if (src.material_unified !== dest.material_unified) update.data.push({ role: 5, b: dest.material_unified });
    if (src.centimeters2 !== dest.centimeters2) update.data.push({ role: 4, i: dest.centimeters2 });
    if (src.material !== dest.material) update.data.push({ role: 3, s: dest.material });
    if (src.stage_number !== dest.stage_number) update.data.push({ role: 2, i: dest.stage_number });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 1, u: dest.order_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.LaserEngravingStage>, dest: Array<object_msg.LaserEngravingStage>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.order_id.compare(dest[i].order_id) !== 0) continue;
        if (srcObj.stage_number !== dest[i].stage_number) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.LaserEngravingStage): object_msg.LaserEngravingStage { 
    return object_msg.LaserEngravingStage.fromObject(object_msg.LaserEngravingStage.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class WebCrawlerWebsitesModel_Remote extends API.WebCrawlerWebsitesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {WebCrawlerWebsites: Array<object_msg.WebCrawlerWebsites>} {
    return { WebCrawlerWebsites: [] };
  }

  getInfoState () {
    return { WebCrawlerWebsites: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'compressed_text_length'], [4, 'possible_redirect'], [3, 'curl_request_failed'], [2, 'postponed'], [1, 'url'], [6, 'collected_info_json']]) } };
  }

  getMutations () {
    const mutations = {
      add_WebCrawlerWebsites (state: State, payload: object_msg.WebCrawlerWebsites) {
        state.models.WebCrawlerWebsites!.push(payload);
      },
      update_WebCrawlerWebsites (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let WebCrawlerWebsites of state.models.WebCrawlerWebsites!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = WebCrawlerWebsites[state.modelsInfo.WebCrawlerWebsites.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            WebCrawlerWebsites[state.modelsInfo.WebCrawlerWebsites.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_WebCrawlerWebsites (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.WebCrawlerWebsites!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.WebCrawlerWebsites![i][state.modelsInfo.WebCrawlerWebsites.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.WebCrawlerWebsites!.splice(i, 1);
          break;
        }
      },
      reset_all_WebCrawlerWebsites (state: State, payload: object_msg.WebCrawlerWebsites[]) {
        state.models.WebCrawlerWebsites!.length = 0;
        Array.prototype.push.apply(state.models.WebCrawlerWebsites, payload);
      },
      clear_all_WebCrawlerWebsites (state: State) {
        state.models.WebCrawlerWebsites!.length = 0;
      },
      set_valid_WebCrawlerWebsites (state: State, payload: boolean) {
        state.modelsInfo.WebCrawlerWebsites.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_WebCrawlerWebsites', object_msg.WebCrawlerWebsites.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_WebCrawlerWebsites', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_WebCrawlerWebsites', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_WebCrawlerWebsites', object_msg.WebCrawlerWebsitesArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_WebCrawlerWebsites');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_WebCrawlerWebsites', valid);
  }

  static addMsgObject (dest: object_msg.WebCrawlerWebsites): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.WebCrawlerWebsites.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.WebCrawlerWebsites): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.url }] }) });
  }

  static updateMsgObject (src: object_msg.WebCrawlerWebsites, dest: object_msg.WebCrawlerWebsites): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.url }];
    if (src.compressed_text_length !== dest.compressed_text_length) update.data.push({ role: 5, i: dest.compressed_text_length });
    if (src.possible_redirect !== dest.possible_redirect) update.data.push({ role: 4, s: dest.possible_redirect });
    if (src.curl_request_failed !== dest.curl_request_failed) update.data.push({ role: 3, b: dest.curl_request_failed });
    if (src.postponed !== dest.postponed) update.data.push({ role: 2, b: dest.postponed });
    if (src.url !== dest.url) update.data.push({ role: 1, s: dest.url });
    if (src.collected_info_json !== dest.collected_info_json) update.data.push({ role: 6, s: dest.collected_info_json });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.WebCrawlerWebsites>, dest: Array<object_msg.WebCrawlerWebsites>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.url !== dest[i].url) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.WebCrawlerWebsites): object_msg.WebCrawlerWebsites { 
    return object_msg.WebCrawlerWebsites.fromObject(object_msg.WebCrawlerWebsites.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class WebCrawlerPendingPagesModel_Remote extends API.WebCrawlerPendingPagesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {WebCrawlerPendingPages: Array<object_msg.WebCrawlerPendingPage>} {
    return { WebCrawlerPendingPages: [] };
  }

  getInfoState () {
    return { WebCrawlerPendingPages: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'sent_actions'], [4, 'compressed_text'], [11, 'parsed_urls_inn'], [10, 'parsed_actions'], [9, 'sent_approved_price_urls'], [8, 'sent_name_phones_emails'], [14, 'parsed_approved_price_urls'], [13, 'parsed_name_phones_emails'], [12, 'parsed_prices'], [3, 'original_text'], [2, 'pending_url'], [1, 'main_url'], [7, 'sent_prices'], [6, 'sent_urls_inn']]) } };
  }

  getMutations () {
    const mutations = {
      add_WebCrawlerPendingPage (state: State, payload: object_msg.WebCrawlerPendingPage) {
        state.models.WebCrawlerPendingPages!.push(payload);
      },
      update_WebCrawlerPendingPage (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let WebCrawlerPendingPage of state.models.WebCrawlerPendingPages!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = WebCrawlerPendingPage[state.modelsInfo.WebCrawlerPendingPages.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            WebCrawlerPendingPage[state.modelsInfo.WebCrawlerPendingPages.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_WebCrawlerPendingPage (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.WebCrawlerPendingPages!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.WebCrawlerPendingPages![i][state.modelsInfo.WebCrawlerPendingPages.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.WebCrawlerPendingPages!.splice(i, 1);
          break;
        }
      },
      reset_all_WebCrawlerPendingPage (state: State, payload: object_msg.WebCrawlerPendingPage[]) {
        state.models.WebCrawlerPendingPages!.length = 0;
        Array.prototype.push.apply(state.models.WebCrawlerPendingPages, payload);
      },
      clear_all_WebCrawlerPendingPage (state: State) {
        state.models.WebCrawlerPendingPages!.length = 0;
      },
      set_valid_WebCrawlerPendingPage (state: State, payload: boolean) {
        state.modelsInfo.WebCrawlerPendingPages.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_WebCrawlerPendingPage', object_msg.WebCrawlerPendingPage.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_WebCrawlerPendingPage', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_WebCrawlerPendingPage', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_WebCrawlerPendingPage', object_msg.WebCrawlerPendingPageArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_WebCrawlerPendingPage');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_WebCrawlerPendingPage', valid);
  }

  static addMsgObject (dest: object_msg.WebCrawlerPendingPage): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.WebCrawlerPendingPage.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.WebCrawlerPendingPage): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.main_url }, { role: 2, s: src.pending_url }] }) });
  }

  static updateMsgObject (src: object_msg.WebCrawlerPendingPage, dest: object_msg.WebCrawlerPendingPage): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.main_url }, { role: 2, s: src.pending_url }];
    if (src.sent_actions !== dest.sent_actions) update.data.push({ role: 5, b: dest.sent_actions });
    if (src.compressed_text !== dest.compressed_text) update.data.push({ role: 4, s: dest.compressed_text });
    if (src.parsed_urls_inn !== dest.parsed_urls_inn) update.data.push({ role: 11, b: dest.parsed_urls_inn });
    if (src.parsed_actions !== dest.parsed_actions) update.data.push({ role: 10, b: dest.parsed_actions });
    if (src.sent_approved_price_urls !== dest.sent_approved_price_urls) update.data.push({ role: 9, b: dest.sent_approved_price_urls });
    if (src.sent_name_phones_emails !== dest.sent_name_phones_emails) update.data.push({ role: 8, b: dest.sent_name_phones_emails });
    if (src.parsed_approved_price_urls !== dest.parsed_approved_price_urls) update.data.push({ role: 14, b: dest.parsed_approved_price_urls });
    if (src.parsed_name_phones_emails !== dest.parsed_name_phones_emails) update.data.push({ role: 13, b: dest.parsed_name_phones_emails });
    if (src.parsed_prices !== dest.parsed_prices) update.data.push({ role: 12, b: dest.parsed_prices });
    if (src.original_text !== dest.original_text) update.data.push({ role: 3, s: dest.original_text });
    if (src.pending_url !== dest.pending_url) update.data.push({ role: 2, s: dest.pending_url });
    if (src.main_url !== dest.main_url) update.data.push({ role: 1, s: dest.main_url });
    if (src.sent_prices !== dest.sent_prices) update.data.push({ role: 7, b: dest.sent_prices });
    if (src.sent_urls_inn !== dest.sent_urls_inn) update.data.push({ role: 6, b: dest.sent_urls_inn });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.WebCrawlerPendingPage>, dest: Array<object_msg.WebCrawlerPendingPage>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.main_url !== dest[i].main_url) continue;
        if (srcObj.pending_url !== dest[i].pending_url) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.WebCrawlerPendingPage): object_msg.WebCrawlerPendingPage { 
    return object_msg.WebCrawlerPendingPage.fromObject(object_msg.WebCrawlerPendingPage.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class WebCrawlerUselessPathsModel_Remote extends API.WebCrawlerUselessPathsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {WebCrawlerUselessPaths: Array<object_msg.WebCrawlerUselessPath>} {
    return { WebCrawlerUselessPaths: [] };
  }

  getInfoState () {
    return { WebCrawlerUselessPaths: { valid: false, roleToRoleNameMap: new Map<number, string>([[2, 'permanent'], [1, 'path']]) } };
  }

  getMutations () {
    const mutations = {
      add_WebCrawlerUselessPath (state: State, payload: object_msg.WebCrawlerUselessPath) {
        state.models.WebCrawlerUselessPaths!.push(payload);
      },
      update_WebCrawlerUselessPath (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let WebCrawlerUselessPath of state.models.WebCrawlerUselessPaths!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = WebCrawlerUselessPath[state.modelsInfo.WebCrawlerUselessPaths.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            WebCrawlerUselessPath[state.modelsInfo.WebCrawlerUselessPaths.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_WebCrawlerUselessPath (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.WebCrawlerUselessPaths!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.WebCrawlerUselessPaths![i][state.modelsInfo.WebCrawlerUselessPaths.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.WebCrawlerUselessPaths!.splice(i, 1);
          break;
        }
      },
      reset_all_WebCrawlerUselessPath (state: State, payload: object_msg.WebCrawlerUselessPath[]) {
        state.models.WebCrawlerUselessPaths!.length = 0;
        Array.prototype.push.apply(state.models.WebCrawlerUselessPaths, payload);
      },
      clear_all_WebCrawlerUselessPath (state: State) {
        state.models.WebCrawlerUselessPaths!.length = 0;
      },
      set_valid_WebCrawlerUselessPath (state: State, payload: boolean) {
        state.modelsInfo.WebCrawlerUselessPaths.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_WebCrawlerUselessPath', object_msg.WebCrawlerUselessPath.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_WebCrawlerUselessPath', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_WebCrawlerUselessPath', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_WebCrawlerUselessPath', object_msg.WebCrawlerUselessPathArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_WebCrawlerUselessPath');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_WebCrawlerUselessPath', valid);
  }

  static addMsgObject (dest: object_msg.WebCrawlerUselessPath): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.WebCrawlerUselessPath.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.WebCrawlerUselessPath): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.path }] }) });
  }

  static updateMsgObject (src: object_msg.WebCrawlerUselessPath, dest: object_msg.WebCrawlerUselessPath): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.path }];
    if (src.permanent !== dest.permanent) update.data.push({ role: 2, b: dest.permanent });
    if (src.path !== dest.path) update.data.push({ role: 1, s: dest.path });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.WebCrawlerUselessPath>, dest: Array<object_msg.WebCrawlerUselessPath>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.path !== dest[i].path) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.WebCrawlerUselessPath): object_msg.WebCrawlerUselessPath { 
    return object_msg.WebCrawlerUselessPath.fromObject(object_msg.WebCrawlerUselessPath.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class WebCrawlerPricePathsModel_Remote extends API.WebCrawlerPricePathsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {WebCrawlerPricePaths: Array<object_msg.WebCrawlerPricePath>} {
    return { WebCrawlerPricePaths: [] };
  }

  getInfoState () {
    return { WebCrawlerPricePaths: { valid: false, roleToRoleNameMap: new Map<number, string>([[1, 'path']]) } };
  }

  getMutations () {
    const mutations = {
      add_WebCrawlerPricePath (state: State, payload: object_msg.WebCrawlerPricePath) {
        state.models.WebCrawlerPricePaths!.push(payload);
      },
      update_WebCrawlerPricePath (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let WebCrawlerPricePath of state.models.WebCrawlerPricePaths!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = WebCrawlerPricePath[state.modelsInfo.WebCrawlerPricePaths.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            WebCrawlerPricePath[state.modelsInfo.WebCrawlerPricePaths.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_WebCrawlerPricePath (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.WebCrawlerPricePaths!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.WebCrawlerPricePaths![i][state.modelsInfo.WebCrawlerPricePaths.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.WebCrawlerPricePaths!.splice(i, 1);
          break;
        }
      },
      reset_all_WebCrawlerPricePath (state: State, payload: object_msg.WebCrawlerPricePath[]) {
        state.models.WebCrawlerPricePaths!.length = 0;
        Array.prototype.push.apply(state.models.WebCrawlerPricePaths, payload);
      },
      clear_all_WebCrawlerPricePath (state: State) {
        state.models.WebCrawlerPricePaths!.length = 0;
      },
      set_valid_WebCrawlerPricePath (state: State, payload: boolean) {
        state.modelsInfo.WebCrawlerPricePaths.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_WebCrawlerPricePath', object_msg.WebCrawlerPricePath.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_WebCrawlerPricePath', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_WebCrawlerPricePath', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_WebCrawlerPricePath', object_msg.WebCrawlerPricePathArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_WebCrawlerPricePath');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_WebCrawlerPricePath', valid);
  }

  static addMsgObject (dest: object_msg.WebCrawlerPricePath): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.WebCrawlerPricePath.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.WebCrawlerPricePath): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.path }] }) });
  }

  static updateMsgObject (src: object_msg.WebCrawlerPricePath, dest: object_msg.WebCrawlerPricePath): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.path }];
    if (src.path !== dest.path) update.data.push({ role: 1, s: dest.path });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.WebCrawlerPricePath>, dest: Array<object_msg.WebCrawlerPricePath>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.path !== dest[i].path) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.WebCrawlerPricePath): object_msg.WebCrawlerPricePath { 
    return object_msg.WebCrawlerPricePath.fromObject(object_msg.WebCrawlerPricePath.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class DepartmentsModel_Remote extends API.DepartmentsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {Departments: Array<object_msg.Department>} {
    return { Departments: [] };
  }

  getInfoState () {
    return { Departments: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'city_id'], [4, 'longitude'], [3, 'latitude'], [2, 'address'], [1, 'id']]) } };
  }

  getMutations () {
    const mutations = {
      add_Department (state: State, payload: object_msg.Department) {
        state.models.Departments!.push(payload);
      },
      update_Department (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let Department of state.models.Departments!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = Department[state.modelsInfo.Departments.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            Department[state.modelsInfo.Departments.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_Department (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.Departments!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.Departments![i][state.modelsInfo.Departments.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.Departments!.splice(i, 1);
          break;
        }
      },
      reset_all_Department (state: State, payload: object_msg.Department[]) {
        state.models.Departments!.length = 0;
        Array.prototype.push.apply(state.models.Departments, payload);
      },
      clear_all_Department (state: State) {
        state.models.Departments!.length = 0;
      },
      set_valid_Department (state: State, payload: boolean) {
        state.modelsInfo.Departments.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_Department', object_msg.Department.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_Department', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_Department', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_Department', object_msg.DepartmentArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_Department');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_Department', valid);
  }

  static addMsgObject (dest: object_msg.Department): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.Department.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.Department): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.Department, dest: object_msg.Department): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.city_id.compare(dest.city_id) !== 0) update.data.push({ role: 5, u: dest.city_id });
    if (src.longitude !== dest.longitude) update.data.push({ role: 4, f: dest.longitude });
    if (src.latitude !== dest.latitude) update.data.push({ role: 3, f: dest.latitude });
    if (src.address !== dest.address) update.data.push({ role: 2, s: dest.address });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.Department>, dest: Array<object_msg.Department>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.Department): object_msg.Department { 
    return object_msg.Department.fromObject(object_msg.Department.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class DepartmentActionsModel_Remote extends API.DepartmentActionsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {DepartmentActions: Array<object_msg.DepartmentAction>} {
    return { DepartmentActions: [] };
  }

  getInfoState () {
    return { DepartmentActions: { valid: false, roleToRoleNameMap: new Map<number, string>([[3, 'markup_percentage'], [2, 'action'], [1, 'department_id']]) } };
  }

  getMutations () {
    const mutations = {
      add_DepartmentAction (state: State, payload: object_msg.DepartmentAction) {
        state.models.DepartmentActions!.push(payload);
      },
      update_DepartmentAction (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let DepartmentAction of state.models.DepartmentActions!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = DepartmentAction[state.modelsInfo.DepartmentActions.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            DepartmentAction[state.modelsInfo.DepartmentActions.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_DepartmentAction (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.DepartmentActions!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.DepartmentActions![i][state.modelsInfo.DepartmentActions.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.DepartmentActions!.splice(i, 1);
          break;
        }
      },
      reset_all_DepartmentAction (state: State, payload: object_msg.DepartmentAction[]) {
        state.models.DepartmentActions!.length = 0;
        Array.prototype.push.apply(state.models.DepartmentActions, payload);
      },
      clear_all_DepartmentAction (state: State) {
        state.models.DepartmentActions!.length = 0;
      },
      set_valid_DepartmentAction (state: State, payload: boolean) {
        state.modelsInfo.DepartmentActions.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_DepartmentAction', object_msg.DepartmentAction.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_DepartmentAction', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_DepartmentAction', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_DepartmentAction', object_msg.DepartmentActionArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_DepartmentAction');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_DepartmentAction', valid);
  }

  static addMsgObject (dest: object_msg.DepartmentAction): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.DepartmentAction.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.DepartmentAction): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.department_id }, { role: 2, s: src.action }] }) });
  }

  static updateMsgObject (src: object_msg.DepartmentAction, dest: object_msg.DepartmentAction): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.department_id }, { role: 2, s: src.action }];
    if (src.markup_percentage !== dest.markup_percentage) update.data.push({ role: 3, i: dest.markup_percentage });
    if (src.action !== dest.action) update.data.push({ role: 2, s: dest.action });
    if (src.department_id.compare(dest.department_id) !== 0) update.data.push({ role: 1, u: dest.department_id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.DepartmentAction>, dest: Array<object_msg.DepartmentAction>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.department_id.compare(dest[i].department_id) !== 0) continue;
        if (srcObj.action !== dest[i].action) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.DepartmentAction): object_msg.DepartmentAction { 
    return object_msg.DepartmentAction.fromObject(object_msg.DepartmentAction.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class LaserCuttingMaterialThicknessesModel_Remote extends API.LaserCuttingMaterialThicknessesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {LaserCuttingMaterialThicknesses: Array<object_msg.LaserCuttingMaterialThickness>} {
    return { LaserCuttingMaterialThicknesses: [] };
  }

  getInfoState () {
    return { LaserCuttingMaterialThicknesses: { valid: false, roleToRoleNameMap: new Map<number, string>([[2, 'thickness_01mm'], [1, 'material']]) } };
  }

  getMutations () {
    const mutations = {
      add_LaserCuttingMaterialThickness (state: State, payload: object_msg.LaserCuttingMaterialThickness) {
        state.models.LaserCuttingMaterialThicknesses!.push(payload);
      },
      update_LaserCuttingMaterialThickness (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let LaserCuttingMaterialThickness of state.models.LaserCuttingMaterialThicknesses!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = LaserCuttingMaterialThickness[state.modelsInfo.LaserCuttingMaterialThicknesses.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            LaserCuttingMaterialThickness[state.modelsInfo.LaserCuttingMaterialThicknesses.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_LaserCuttingMaterialThickness (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.LaserCuttingMaterialThicknesses!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.LaserCuttingMaterialThicknesses![i][state.modelsInfo.LaserCuttingMaterialThicknesses.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.LaserCuttingMaterialThicknesses!.splice(i, 1);
          break;
        }
      },
      reset_all_LaserCuttingMaterialThickness (state: State, payload: object_msg.LaserCuttingMaterialThickness[]) {
        state.models.LaserCuttingMaterialThicknesses!.length = 0;
        Array.prototype.push.apply(state.models.LaserCuttingMaterialThicknesses, payload);
      },
      clear_all_LaserCuttingMaterialThickness (state: State) {
        state.models.LaserCuttingMaterialThicknesses!.length = 0;
      },
      set_valid_LaserCuttingMaterialThickness (state: State, payload: boolean) {
        state.modelsInfo.LaserCuttingMaterialThicknesses.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_LaserCuttingMaterialThickness', object_msg.LaserCuttingMaterialThickness.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_LaserCuttingMaterialThickness', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_LaserCuttingMaterialThickness', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_LaserCuttingMaterialThickness', object_msg.LaserCuttingMaterialThicknessArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_LaserCuttingMaterialThickness');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_LaserCuttingMaterialThickness', valid);
  }

  static addMsgObject (dest: object_msg.LaserCuttingMaterialThickness): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.LaserCuttingMaterialThickness.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.LaserCuttingMaterialThickness): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.material }, { role: 2, i: src.thickness_01mm }] }) });
  }

  static updateMsgObject (src: object_msg.LaserCuttingMaterialThickness, dest: object_msg.LaserCuttingMaterialThickness): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.material }, { role: 2, i: src.thickness_01mm }];
    if (src.thickness_01mm !== dest.thickness_01mm) update.data.push({ role: 2, i: dest.thickness_01mm });
    if (src.material !== dest.material) update.data.push({ role: 1, s: dest.material });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.LaserCuttingMaterialThickness>, dest: Array<object_msg.LaserCuttingMaterialThickness>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.material !== dest[i].material) continue;
        if (srcObj.thickness_01mm !== dest[i].thickness_01mm) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.LaserCuttingMaterialThickness): object_msg.LaserCuttingMaterialThickness { 
    return object_msg.LaserCuttingMaterialThickness.fromObject(object_msg.LaserCuttingMaterialThickness.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class ReceiptPositionsModel_Remote extends API.ReceiptPositionsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {ReceiptPositions: Array<object_msg.ReceiptPosition>} {
    return { ReceiptPositions: [] };
  }

  getInfoState () {
    return { ReceiptPositions: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'stage_number'], [4, 'position_type'], [10, 'service_id'], [9, 'organization_id'], [8, 'order_id'], [3, 'name'], [2, 'receipt_position'], [1, 'receipt_id'], [7, 'amount'], [6, 'price']]) } };
  }

  getMutations () {
    const mutations = {
      add_ReceiptPosition (state: State, payload: object_msg.ReceiptPosition) {
        state.models.ReceiptPositions!.push(payload);
      },
      update_ReceiptPosition (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let ReceiptPosition of state.models.ReceiptPositions!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = ReceiptPosition[state.modelsInfo.ReceiptPositions.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            ReceiptPosition[state.modelsInfo.ReceiptPositions.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_ReceiptPosition (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.ReceiptPositions!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.ReceiptPositions![i][state.modelsInfo.ReceiptPositions.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.ReceiptPositions!.splice(i, 1);
          break;
        }
      },
      reset_all_ReceiptPosition (state: State, payload: object_msg.ReceiptPosition[]) {
        state.models.ReceiptPositions!.length = 0;
        Array.prototype.push.apply(state.models.ReceiptPositions, payload);
      },
      clear_all_ReceiptPosition (state: State) {
        state.models.ReceiptPositions!.length = 0;
      },
      set_valid_ReceiptPosition (state: State, payload: boolean) {
        state.modelsInfo.ReceiptPositions.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_ReceiptPosition', object_msg.ReceiptPosition.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_ReceiptPosition', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_ReceiptPosition', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_ReceiptPosition', object_msg.ReceiptPositionArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_ReceiptPosition');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_ReceiptPosition', valid);
  }

  static addMsgObject (dest: object_msg.ReceiptPosition): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.ReceiptPosition.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.ReceiptPosition): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.receipt_id }, { role: 2, i: src.receipt_position }] }) });
  }

  static updateMsgObject (src: object_msg.ReceiptPosition, dest: object_msg.ReceiptPosition): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.receipt_id }, { role: 2, i: src.receipt_position }];
    if (src.stage_number !== dest.stage_number) update.data.push({ role: 5, i: dest.stage_number });
    if (src.position_type !== dest.position_type) update.data.push({ role: 4, s: dest.position_type });
    if (src.service_id.compare(dest.service_id) !== 0) update.data.push({ role: 10, u: dest.service_id });
    if (src.organization_id.compare(dest.organization_id) !== 0) update.data.push({ role: 9, u: dest.organization_id });
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 8, u: dest.order_id });
    if (src.name !== dest.name) update.data.push({ role: 3, s: dest.name });
    if (src.receipt_position !== dest.receipt_position) update.data.push({ role: 2, i: dest.receipt_position });
    if (src.receipt_id.compare(dest.receipt_id) !== 0) update.data.push({ role: 1, u: dest.receipt_id });
    if (src.amount !== dest.amount) update.data.push({ role: 7, f: dest.amount });
    if (src.price !== dest.price) update.data.push({ role: 6, f: dest.price });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.ReceiptPosition>, dest: Array<object_msg.ReceiptPosition>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.receipt_id.compare(dest[i].receipt_id) !== 0) continue;
        if (srcObj.receipt_position !== dest[i].receipt_position) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.ReceiptPosition): object_msg.ReceiptPosition { 
    return object_msg.ReceiptPosition.fromObject(object_msg.ReceiptPosition.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class OrderServicesModel_Remote extends API.OrderServicesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {OrderServices: Array<object_msg.OrderService>} {
    return { OrderServices: [] };
  }

  getInfoState () {
    return { OrderServices: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'order_id'], [4, 'price'], [3, 'type'], [2, 'name'], [1, 'id']]) } };
  }

  getMutations () {
    const mutations = {
      add_OrderService (state: State, payload: object_msg.OrderService) {
        state.models.OrderServices!.push(payload);
      },
      update_OrderService (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let OrderService of state.models.OrderServices!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = OrderService[state.modelsInfo.OrderServices.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            OrderService[state.modelsInfo.OrderServices.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_OrderService (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.OrderServices!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.OrderServices![i][state.modelsInfo.OrderServices.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.OrderServices!.splice(i, 1);
          break;
        }
      },
      reset_all_OrderService (state: State, payload: object_msg.OrderService[]) {
        state.models.OrderServices!.length = 0;
        Array.prototype.push.apply(state.models.OrderServices, payload);
      },
      clear_all_OrderService (state: State) {
        state.models.OrderServices!.length = 0;
      },
      set_valid_OrderService (state: State, payload: boolean) {
        state.modelsInfo.OrderServices.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_OrderService', object_msg.OrderService.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_OrderService', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_OrderService', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_OrderService', object_msg.OrderServiceArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_OrderService');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_OrderService', valid);
  }

  static addMsgObject (dest: object_msg.OrderService): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.OrderService.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.OrderService): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.OrderService, dest: object_msg.OrderService): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.order_id.compare(dest.order_id) !== 0) update.data.push({ role: 5, u: dest.order_id });
    if (src.price !== dest.price) update.data.push({ role: 4, f: dest.price });
    if (src.type !== dest.type) update.data.push({ role: 3, s: dest.type });
    if (src.name !== dest.name) update.data.push({ role: 2, s: dest.name });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.OrderService>, dest: Array<object_msg.OrderService>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.OrderService): object_msg.OrderService { 
    return object_msg.OrderService.fromObject(object_msg.OrderService.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class SecondaryArticlesModel_Remote extends API.SecondaryArticlesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {SecondaryArticles: Array<object_msg.SecondaryArticle>} {
    return { SecondaryArticles: [] };
  }

  getInfoState () {
    return { SecondaryArticles: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'article'], [4, 'head_title_and_meta_description'], [11, 'material_group_for_prices'], [10, 'action_url_name'], [9, 'author_id'], [8, 'draft'], [12, 'additional_footer_elements'], [3, 'url_name'], [2, 'title'], [1, 'action'], [7, 'open_graph'], [6, 'json_ld']]) } };
  }

  getMutations () {
    const mutations = {
      add_SecondaryArticle (state: State, payload: object_msg.SecondaryArticle) {
        state.models.SecondaryArticles!.push(payload);
      },
      update_SecondaryArticle (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let SecondaryArticle of state.models.SecondaryArticles!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = SecondaryArticle[state.modelsInfo.SecondaryArticles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            SecondaryArticle[state.modelsInfo.SecondaryArticles.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_SecondaryArticle (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.SecondaryArticles!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.SecondaryArticles![i][state.modelsInfo.SecondaryArticles.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.SecondaryArticles!.splice(i, 1);
          break;
        }
      },
      reset_all_SecondaryArticle (state: State, payload: object_msg.SecondaryArticle[]) {
        state.models.SecondaryArticles!.length = 0;
        Array.prototype.push.apply(state.models.SecondaryArticles, payload);
      },
      clear_all_SecondaryArticle (state: State) {
        state.models.SecondaryArticles!.length = 0;
      },
      set_valid_SecondaryArticle (state: State, payload: boolean) {
        state.modelsInfo.SecondaryArticles.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_SecondaryArticle', object_msg.SecondaryArticle.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_SecondaryArticle', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_SecondaryArticle', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_SecondaryArticle', object_msg.SecondaryArticleArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_SecondaryArticle');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_SecondaryArticle', valid);
  }

  static addMsgObject (dest: object_msg.SecondaryArticle): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.SecondaryArticle.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.SecondaryArticle): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, s: src.action }, { role: 2, s: src.title }] }) });
  }

  static updateMsgObject (src: object_msg.SecondaryArticle, dest: object_msg.SecondaryArticle): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, s: src.action }, { role: 2, s: src.title }];
    if (src.article !== dest.article) update.data.push({ role: 5, s: dest.article });
    if (src.head_title_and_meta_description !== dest.head_title_and_meta_description) update.data.push({ role: 4, s: dest.head_title_and_meta_description });
    if (src.material_group_for_prices !== dest.material_group_for_prices) update.data.push({ role: 11, s: dest.material_group_for_prices });
    if (src.action_url_name !== dest.action_url_name) update.data.push({ role: 10, s: dest.action_url_name });
    if (src.author_id.compare(dest.author_id) !== 0) update.data.push({ role: 9, u: dest.author_id });
    if (src.draft !== dest.draft) update.data.push({ role: 8, b: dest.draft });
    if (src.additional_footer_elements !== dest.additional_footer_elements) update.data.push({ role: 12, s: dest.additional_footer_elements });
    if (src.url_name !== dest.url_name) update.data.push({ role: 3, s: dest.url_name });
    if (src.title !== dest.title) update.data.push({ role: 2, s: dest.title });
    if (src.action !== dest.action) update.data.push({ role: 1, s: dest.action });
    if (src.open_graph !== dest.open_graph) update.data.push({ role: 7, s: dest.open_graph });
    if (src.json_ld !== dest.json_ld) update.data.push({ role: 6, s: dest.json_ld });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.SecondaryArticle>, dest: Array<object_msg.SecondaryArticle>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.action !== dest[i].action) continue;
        if (srcObj.title !== dest[i].title) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.SecondaryArticle): object_msg.SecondaryArticle { 
    return object_msg.SecondaryArticle.fromObject(object_msg.SecondaryArticle.toObject(src, { longs: String })) 
  }
}

// Allowed roles:
// Manager, Admin
// Row restriction based on role and user name:
// 
export class ArticleAuthorsModel_Remote extends API.ArticleAuthorsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {ArticleAuthors: Array<object_msg.ArticleAuthor>} {
    return { ArticleAuthors: [] };
  }

  getInfoState () {
    return { ArticleAuthors: { valid: false, roleToRoleNameMap: new Map<number, string>([[5, 'author_page_article'], [4, 'author_description'], [10, 'draft'], [9, 'url_name'], [8, 'open_graph'], [3, 'full_name'], [2, 'name_for_articles'], [1, 'id'], [7, 'json_ld'], [6, 'head_title_and_meta_description']]) } };
  }

  getMutations () {
    const mutations = {
      add_ArticleAuthor (state: State, payload: object_msg.ArticleAuthor) {
        state.models.ArticleAuthors!.push(payload);
      },
      update_ArticleAuthor (state: State, payload: object_update_msg.IUpdate) {
        rowIterator: for (let ArticleAuthor of state.models.ArticleAuthors!) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = ArticleAuthor[state.modelsInfo.ArticleAuthors.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          for (let dataPart of payload.data!) {
            // @ts-expect-error: Auto generated code have to be right
            ArticleAuthor[state.modelsInfo.ArticleAuthors.roleToRoleNameMap.get(dataPart.role!)!] = extractEntryFieldValue(dataPart);
          }
          break;
        }
      },
      remove_ArticleAuthor (state: State, payload: object_update_msg.IRemove) {
        rowIterator: for (let i = 0; i < state.models.ArticleAuthors!.length; i++) {
          for (let indexPart of payload.index!) {
            // @ts-expect-error: Auto generated code have to be right
            const srcIndex = state.models.ArticleAuthors![i][state.modelsInfo.ArticleAuthors.roleToRoleNameMap.get(indexPart.role!)!];
            const index = extractEntryFieldValue(indexPart);
            // @ts-expect-error: Long cast
            if (Long.isLong(srcIndex)) { if (srcIndex.compare(index) !== 0) continue rowIterator; } else { if (srcIndex !== index) continue rowIterator; }
          }
          state.models.ArticleAuthors!.splice(i, 1);
          break;
        }
      },
      reset_all_ArticleAuthor (state: State, payload: object_msg.ArticleAuthor[]) {
        state.models.ArticleAuthors!.length = 0;
        Array.prototype.push.apply(state.models.ArticleAuthors, payload);
      },
      clear_all_ArticleAuthor (state: State) {
        state.models.ArticleAuthors!.length = 0;
      },
      set_valid_ArticleAuthor (state: State, payload: boolean) {
        state.modelsInfo.ArticleAuthors.valid = payload;
      }
    }
    return mutations;
  }

  addRow (row: Uint8Array): void {
    this.#store.commit('add_ArticleAuthor', object_msg.ArticleAuthor.decode(row));
  }

  updateRow (payload: object_update_msg.IUpdate): void {
    this.#store.commit('update_ArticleAuthor', payload);
  }

  removeRow (payload: object_update_msg.IRemove): void {
    this.#store.commit('remove_ArticleAuthor', payload);
  }

  resetAll (modelData: Uint8Array): void {
    this.#store.commit('reset_all_ArticleAuthor', object_msg.ArticleAuthorArray.decode(modelData).array);
  }

  clearAll (): void {
    this.#store.commit('clear_all_ArticleAuthor');
  }

  setValid (valid: boolean): void {
    this.#store.commit('set_valid_ArticleAuthor', valid);
  }

  static addMsgObject (dest: object_msg.ArticleAuthor): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ add_encoded: object_msg.ArticleAuthor.encode(dest).finish() });
  }

  static removeMsgObject (src: object_msg.ArticleAuthor): object_update_msg.ObjectUpdate {
    return new object_update_msg.ObjectUpdate({ remove: new object_update_msg.Remove({ index: [{ role: 1, u: src.id }] }) });
  }

  static updateMsgObject (src: object_msg.ArticleAuthor, dest: object_msg.ArticleAuthor): object_update_msg.ObjectUpdate {
    const update = new object_update_msg.Update();
    update.index = [{ role: 1, u: src.id }];
    if (src.author_page_article !== dest.author_page_article) update.data.push({ role: 5, s: dest.author_page_article });
    if (src.author_description !== dest.author_description) update.data.push({ role: 4, s: dest.author_description });
    if (src.draft !== dest.draft) update.data.push({ role: 10, b: dest.draft });
    if (src.url_name !== dest.url_name) update.data.push({ role: 9, s: dest.url_name });
    if (src.open_graph !== dest.open_graph) update.data.push({ role: 8, s: dest.open_graph });
    if (src.full_name !== dest.full_name) update.data.push({ role: 3, s: dest.full_name });
    if (src.name_for_articles !== dest.name_for_articles) update.data.push({ role: 2, s: dest.name_for_articles });
    if (src.id.compare(dest.id) !== 0) update.data.push({ role: 1, u: dest.id });
    if (src.json_ld !== dest.json_ld) update.data.push({ role: 7, s: dest.json_ld });
    if (src.head_title_and_meta_description !== dest.head_title_and_meta_description) update.data.push({ role: 6, s: dest.head_title_and_meta_description });
    return new object_update_msg.ObjectUpdate({ update });
  }

  static findDiff (src: Array<object_msg.ArticleAuthor>, dest: Array<object_msg.ArticleAuthor>): object_update_msg.ObjectUpdateArray {
    const updateArray = new object_update_msg.ObjectUpdateArray();
    let addIndexes: Array<number> = [...dest.keys()];
    for (let srcObj of src) {
      let found = false;
      for (let i = 0; i < dest.length; i++) {
        if (srcObj.id.compare(dest[i].id) !== 0) continue;
        const updateObj = this.updateMsgObject(srcObj, dest[i]);
        if (updateObj.update!.data!.length) updateArray.array.push(updateObj);
        addIndexes[i] = -1;
        found = true;
      }
      if (!found) updateArray.array.push(this.removeMsgObject(srcObj));
    }
    for (let i of addIndexes) if (i !== -1) updateArray.array.push(this.addMsgObject(dest[i]));
    return updateArray;
  }
  
  static cloneEntry (src: object_msg.ArticleAuthor): object_msg.ArticleAuthor { 
    return object_msg.ArticleAuthor.fromObject(object_msg.ArticleAuthor.toObject(src, { longs: String })) 
  }
}

export function createStore (rpcClient: RpcClient, socket: WebSocketRpc, customState: Record<string, any>, customMutations: any) : Store<State> {
  const models: Array<AbstractModel_Remote> = [];
  
  // generated models

  const ActionTypesModel = new ActionTypesModel_Remote(socket);
  models.push(ActionTypesModel);

  const WebsiteActionsModel = new WebsiteActionsModel_Remote(socket);
  models.push(WebsiteActionsModel);

  const ActionMaterialsModel = new ActionMaterialsModel_Remote(socket);
  models.push(ActionMaterialsModel);

  const MachineTypesModel = new MachineTypesModel_Remote(socket);
  models.push(MachineTypesModel);

  const MaterialTypesModel = new MaterialTypesModel_Remote(socket);
  models.push(MaterialTypesModel);

  const LabelTypesModel = new LabelTypesModel_Remote(socket);
  models.push(LabelTypesModel);

  const PlacesModel = new PlacesModel_Remote(socket);
  models.push(PlacesModel);

  const OrganizationsRuModel = new OrganizationsRuModel_Remote(socket);
  models.push(OrganizationsRuModel);

  const OrganizationsRuInfoModel = new OrganizationsRuInfoModel_Remote(socket);
  models.push(OrganizationsRuInfoModel);

  const BanksRuModel = new BanksRuModel_Remote(socket);
  models.push(BanksRuModel);

  const PlaceActionsModel = new PlaceActionsModel_Remote(socket);
  models.push(PlaceActionsModel);

  const PlacePhonesModel = new PlacePhonesModel_Remote(socket);
  models.push(PlacePhonesModel);

  const PlaceEmailsModel = new PlaceEmailsModel_Remote(socket);
  models.push(PlaceEmailsModel);

  const PlaceMachinesModel = new PlaceMachinesModel_Remote(socket);
  models.push(PlaceMachinesModel);

  const PlaceLabelsModel = new PlaceLabelsModel_Remote(socket);
  models.push(PlaceLabelsModel);

  const LaserCuttingPricesModel = new LaserCuttingPricesModel_Remote(socket);
  models.push(LaserCuttingPricesModel);

  const LocalLaserCuttingPricesModel = new LocalLaserCuttingPricesModel_Remote(socket);
  models.push(LocalLaserCuttingPricesModel);

  const LaserEngravingPricesModel = new LaserEngravingPricesModel_Remote(socket);
  models.push(LaserEngravingPricesModel);

  const LocalLaserEngravingPricesModel = new LocalLaserEngravingPricesModel_Remote(socket);
  models.push(LocalLaserEngravingPricesModel);

  const LogsModel = new LogsModel_Remote(socket);
  models.push(LogsModel);

  const CitiesRuModel = new CitiesRuModel_Remote(socket);
  models.push(CitiesRuModel);

  const CityActionsModel = new CityActionsModel_Remote(socket);
  models.push(CityActionsModel);

  const OrdersModel = new OrdersModel_Remote(socket);
  models.push(OrdersModel);

  const OrderStatusesModel = new OrderStatusesModel_Remote(socket);
  models.push(OrderStatusesModel);

  const OrderCommentsModel = new OrderCommentsModel_Remote(socket);
  models.push(OrderCommentsModel);

  const OrderCommentFilesModel = new OrderCommentFilesModel_Remote(socket);
  models.push(OrderCommentFilesModel);

  const UserDeliveryProfilesModel = new UserDeliveryProfilesModel_Remote(socket);
  models.push(UserDeliveryProfilesModel);

  const OrderStagesModel = new OrderStagesModel_Remote(socket);
  models.push(OrderStagesModel);

  const OrderStageStatusesModel = new OrderStageStatusesModel_Remote(socket);
  models.push(OrderStageStatusesModel);

  const OrderStageDeliveryProfilesModel = new OrderStageDeliveryProfilesModel_Remote(socket);
  models.push(OrderStageDeliveryProfilesModel);

  const OrderStageCommentsModel = new OrderStageCommentsModel_Remote(socket);
  models.push(OrderStageCommentsModel);

  const OrderStageCommentFilesModel = new OrderStageCommentFilesModel_Remote(socket);
  models.push(OrderStageCommentFilesModel);

  const UploadedFilesModel = new UploadedFilesModel_Remote(socket);
  models.push(UploadedFilesModel);

  const CommercialOfferRequestsModel = new CommercialOfferRequestsModel_Remote(socket);
  models.push(CommercialOfferRequestsModel);

  const LaserCuttingStagesModel = new LaserCuttingStagesModel_Remote(socket);
  models.push(LaserCuttingStagesModel);

  const LaserEngravingStagesModel = new LaserEngravingStagesModel_Remote(socket);
  models.push(LaserEngravingStagesModel);

  const WebCrawlerWebsitesModel = new WebCrawlerWebsitesModel_Remote(socket);
  models.push(WebCrawlerWebsitesModel);

  const WebCrawlerPendingPagesModel = new WebCrawlerPendingPagesModel_Remote(socket);
  models.push(WebCrawlerPendingPagesModel);

  const WebCrawlerUselessPathsModel = new WebCrawlerUselessPathsModel_Remote(socket);
  models.push(WebCrawlerUselessPathsModel);

  const WebCrawlerPricePathsModel = new WebCrawlerPricePathsModel_Remote(socket);
  models.push(WebCrawlerPricePathsModel);

  const DepartmentsModel = new DepartmentsModel_Remote(socket);
  models.push(DepartmentsModel);

  const DepartmentActionsModel = new DepartmentActionsModel_Remote(socket);
  models.push(DepartmentActionsModel);

  const LaserCuttingMaterialThicknessesModel = new LaserCuttingMaterialThicknessesModel_Remote(socket);
  models.push(LaserCuttingMaterialThicknessesModel);

  const ReceiptPositionsModel = new ReceiptPositionsModel_Remote(socket);
  models.push(ReceiptPositionsModel);

  const OrderServicesModel = new OrderServicesModel_Remote(socket);
  models.push(OrderServicesModel);

  const SecondaryArticlesModel = new SecondaryArticlesModel_Remote(socket);
  models.push(SecondaryArticlesModel);

  const ArticleAuthorsModel = new ArticleAuthorsModel_Remote(socket);
  models.push(ArticleAuthorsModel);

  rpcClient.setModels(models);

  // auth data
  const authData: AuthStoreData = { isConnected: false, isAuthenticated: false, userPhone: '', userRole: 'Undefined', isPolicyAccepted: false, agentContractRequested: false, agentContractConcluded: false };
  const authMutations = {  
    authChanged (state: State, payload: { isAuthenticated: boolean, userPhone: string, userRole: string, isPolicyAccepted: boolean, agentContractRequested: boolean, agentContractConcluded: boolean}) {
      state.auth.isAuthenticated = payload.isAuthenticated; state.auth.userPhone = payload.userPhone; state.auth.userRole = payload.userRole;
      state.auth.isPolicyAccepted = payload.isPolicyAccepted; state.auth.agentContractRequested = payload.agentContractRequested;
      state.auth.agentContractConcluded = payload.agentContractConcluded;
    },
    connectionChanged (state: State, payload: { isConnected: boolean}) {
      state.auth.isConnected = payload.isConnected;
    }
  }
  onConnectionChanged.addListener(newConnection => store.commit('connectionChanged', { isConnected: newConnection.isConnected }));
  onAuthChanged.addListener(newAuth => store.commit('authChanged', { isAuthenticated: newAuth.isAuthenticated, userPhone: newAuth.userPhone, userRole: newAuth.userRole }));

  // request status
  const requestInfo: RequestInfo = { pending: false, error: false };
  const requestMutations = {
    requestChanged (state: State, payload: { pending: boolean, error: boolean }) {
      state.request.pending = payload.pending; state.request.error = payload.error;
    }
  }
  onRequestChanged.addListener(newRequest => store.commit('requestChanged', { pending: newRequest.pending, error: newRequest.error }));

  // store make up
  const emptyModelArrays = {};
  const emptyModelsInfo = {};
  const mutations = customMutations;
  Object.assign(mutations, authMutations, requestMutations);
  for (let model of models) {
    Object.assign(emptyModelArrays, model.getState());
    Object.assign(emptyModelsInfo, model.getInfoState());
    Object.assign(mutations, model.getMutations());
  }
  const state = customState;
  state.models = emptyModelArrays;
  state.modelsInfo = emptyModelsInfo;
  state.auth = authData;
  state.request = requestInfo;

  // @ts-expect-error: We are sure that state is right
  const store = baseCreateStore<State>({ state, mutations });

  ActionTypesModel.setStore(store);

  WebsiteActionsModel.setStore(store);

  ActionMaterialsModel.setStore(store);

  MachineTypesModel.setStore(store);

  MaterialTypesModel.setStore(store);

  LabelTypesModel.setStore(store);

  PlacesModel.setStore(store);

  OrganizationsRuModel.setStore(store);

  OrganizationsRuInfoModel.setStore(store);

  BanksRuModel.setStore(store);

  PlaceActionsModel.setStore(store);

  PlacePhonesModel.setStore(store);

  PlaceEmailsModel.setStore(store);

  PlaceMachinesModel.setStore(store);

  PlaceLabelsModel.setStore(store);

  LaserCuttingPricesModel.setStore(store);

  LocalLaserCuttingPricesModel.setStore(store);

  LaserEngravingPricesModel.setStore(store);

  LocalLaserEngravingPricesModel.setStore(store);

  LogsModel.setStore(store);

  CitiesRuModel.setStore(store);

  CityActionsModel.setStore(store);

  OrdersModel.setStore(store);

  OrderStatusesModel.setStore(store);

  OrderCommentsModel.setStore(store);

  OrderCommentFilesModel.setStore(store);

  UserDeliveryProfilesModel.setStore(store);

  OrderStagesModel.setStore(store);

  OrderStageStatusesModel.setStore(store);

  OrderStageDeliveryProfilesModel.setStore(store);

  OrderStageCommentsModel.setStore(store);

  OrderStageCommentFilesModel.setStore(store);

  UploadedFilesModel.setStore(store);

  CommercialOfferRequestsModel.setStore(store);

  LaserCuttingStagesModel.setStore(store);

  LaserEngravingStagesModel.setStore(store);

  WebCrawlerWebsitesModel.setStore(store);

  WebCrawlerPendingPagesModel.setStore(store);

  WebCrawlerUselessPathsModel.setStore(store);

  WebCrawlerPricePathsModel.setStore(store);

  DepartmentsModel.setStore(store);

  DepartmentActionsModel.setStore(store);

  LaserCuttingMaterialThicknessesModel.setStore(store);

  ReceiptPositionsModel.setStore(store);

  OrderServicesModel.setStore(store);

  SecondaryArticlesModel.setStore(store);

  ArticleAuthorsModel.setStore(store);
  return store;
}
