import { computed, watch, onMounted, watchEffect, shallowRef, readonly } from 'vue';
import { Store } from 'vuex'
import { helpers, required } from '@vuelidate/validators';
import { State } from '@/generated/store'
import { object_update_msg } from '@/rpc_client/messages';
import { RpcClient, muteError } from '@/rpc_client/apiAbstract';
import Long from 'long';

export const THEME_COOKIE_ATTRIBUTE = 'path=/;max-age=31536000;' + (process.env.NODE_ENV !== 'production' ? '' : 'domain=.tridecagram.ru;secure;samesite=none;partitioned');

export function onAuth (store: Store<State>, func:() => void) {
  let authenticated = computed(() => store.state.auth.isAuthenticated);
  onMounted(() => { if (authenticated.value) func(); })
  watch(authenticated, (authenticated) => { if (authenticated) func() });
}

export function subscribeToModels (rpcClient: RpcClient, ids: Array<number>) { 
  ids.forEach(id => { rpcClient.subscribeToModel(id).catch(muteError); }) 
}

export function unsubscribeFromModels (rpcClient: RpcClient, ids: Array<number>) { 
  ids.forEach(id => { rpcClient.unsubscribeFromModel(id).catch(muteError); }) 
}

export function extractPhoneRu (value: string): string {
  if (value.length > 20 || value.length < 11) return '';
  let digits = value.match(/\d/g);
  if (!digits) return '';
  if (digits[0] !== '7' && digits[0] !== '8') return '';
  if (digits[0] === '8') digits[0] = '7';
  if (digits.length !== 11) return '';
  return digits.join('');
}

type StageData = {property: string, type: string}

// STAGE_TABLE
export const actionToStageDataMap = new Map([
  ['Лазерная резка', <StageData>{ property: 'laserCuttingStage', type: 'LaserCuttingStage' }],
  ['Лазерная гравировка', <StageData>{ property: 'laserEngravingStage', type: 'LaserEngravingStage' }]
]);
//

export const queryParameterToLinkMap = new Map([
  ['new_order', '/outgoing-orders'],
  // just for tests
  ['options', '/options'],
  ['incoming_orders', '/incoming-orders']
]);

export function getRedirectLinkFromQueryMap (queryMap: Map<any, any>) {
  let queryPath = queryMap.get('to');
  let path = queryParameterToLinkMap.get(queryPath);
  if (queryPath === undefined || path === undefined) return '/outgoing-orders';
  let options = '';
  for (let [key, value] of queryMap) {
    if (key === 'to') continue;
    options += key + '=' + value + '&';
  }
  return path + (options.length ? '/?' + options.slice(0, -1) : '');
}

export enum OrderState {
  NEW_ORDER = 1,
  DELIVERY,
  PAYMENT,
  COMMENT,
  FULL_RENDER,
  max_number
}

export enum Statuses {
  Processing,
  Payment,
  Ongoing,
  Delivery,
  Done,
  Canceled,
  max_number
}

export const StatusMap = new Map([
  ['В обработке', Statuses.Processing], 
  ['Ожидает оплаты', Statuses.Payment], 
  ['На исполнении', Statuses.Ongoing], 
  ['Передан в доставку', Statuses.Delivery], 
  ['Завершен', Statuses.Done], 
  ['Отменен', Statuses.Canceled]
])

export const StatusArray = [...StatusMap.keys()];

export const StatusMapEntries = [...StatusMap.entries()]

export interface UserInfo {
  phone?: string;
  email?: string;
  name?: string;
  surname?: string;
  patronymic?: string;
  communication_type?: number;
  is_policy_accepted?: boolean;
  agent_contract_requested?: boolean,
}

// do not change order in array
// todo: add viber
export const communicationTypesArray = [
  { type: 0, name: 'Отсутствует' },
  { type: 1, name: 'SMS' },
  { type: 2, name: 'WhatsApp' },
  // { type: 3, name: 'Telegram' },
  { type: 4, name: 'Email' }
];

export function isEmailValid (value: string): boolean {
  let emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  if (!value.match(emailRegex)) {
    return false;
  }
  return true;
}

export const RequiredValidator = helpers.withMessage('Обязательное поле', required);
export const PositiveValidator = helpers.withMessage('Должно быть > 0', (value: number) => value > 0);
export const NonZeroValidator = helpers.withMessage('Не должно быть = 0', (value: number) => value !== 0);
export const NonNegativeValidator = helpers.withMessage('Должно быть >= 0', (value: number) => value >= 0);
export const PhoneValidator = helpers.withMessage('Телефон должен быть вида "79999999999"', (value: string) => extractPhoneRu(value).length > 0);

export function packUpdates (array: object_update_msg.ObjectUpdateArray) {
  return object_update_msg.ObjectUpdateArray.encode(array).finish();
}

export function itemFromProps (this: any, properties: any) {
  if (properties) {
    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
      if (properties[keys[i]] != null) { this[keys[i]] = properties[keys[i]]; } 
    } 
  }
}

export function eagerComputed (fn: any) {
  const result = shallowRef()
  watchEffect(() => { result.value = fn() }, { flush: 'sync' })
  return readonly(result)
}

export function getDate (date: Long | Date, withTime = false, withSec = false) {
  let options = { 
    year: '2-digit',
    month: 'numeric', 
    day: 'numeric' 
  };
  if (withTime) Object.assign(options, { hour: 'numeric', minute: 'numeric' })
  if (withTime && withSec) Object.assign(options, { second: 'numeric' })

  if (date instanceof Long) {
    // @ts-expect-error: set types annotation properly
    return new Intl.DateTimeFormat('ru', options).format(new Date(date.toNumber()));
  }
  if (date instanceof Date) {
    // @ts-expect-error: set types annotation properly
    return new Intl.DateTimeFormat('ru', options).format(date);
  }
}

export function dateFormat (date: Date, withTime = false) {
  if (!date) return '';
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let timeString = '';
  if (withTime) {
    let minutes = date.getMinutes();
    timeString = ` ${date.getHours()}:${minutes > 9 ? minutes : '0' + minutes}`;
  }
  return `${day}/${month}/${year}` + timeString;
}

export function getCompactFileName (name: string) {
  let dotIndex = name.lastIndexOf('.');
  if (dotIndex < 15) {
    return name
  }
  return name.substring(0, 15) + '...' + name.substring(dotIndex + 1);
}

export function getInnKppString (inn: string, kpp: string) {
  let innKppString = 'ИНН: ' + inn;
  if (kpp.length) innKppString += ' / КПП: ' + kpp;
  return innKppString;
}

export type ReceiptPosition = {
  name: string, 
  positionType: string, 
  paymentMethod: string, 
  paymentObject?: string,
  amount: number, 
  priceRaw: number,
  price: number, 
  vatTag?: number 
  serviceId: Long, 
  stageNumber: number, 
  notAsAgent: boolean,
  previousPrepayment: number, 
}

export type ContactInfo = {
  receiver: string,
  selected: boolean,
  description:string,
  main: boolean,
  communicationTypes: Array<{communicationType: number, selected: boolean}>
}

/*
function buf2hex (buffer) { // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)].map(x => x.toString(16).padStart(2, '0')).join('');
}

console.log(buf2hex(reader.buf));
*/
